import { StytchB2BHeadlessClient } from '@stytch/vanilla-js/b2b/headless'
import config from 'src/config'

const stytchOptions = {
  cookieOptions: {
    opaqueTokenCookieName: 'session',
    jwtCookieName: 'token',
    path: '/'
  }
}

export const stytchClient = new StytchB2BHeadlessClient(
  config.STYTCH_PUBLIC_KEY,
  stytchOptions
)
