import { gql, useMutation } from '@apollo/client'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useHandleAuthTokens, useLogout } from 'src/app/services/auth'
import {
  useStytchMemberSession,
  useStytchMember
} from '@stytch/react/b2b'
import { SessionTimeoutPopup } from './SessionTimeOutPopup'

import { getToken } from 'src/app/services/auth'
import { useLocation } from 'react-router'

const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    refreshSession {
      token
      session
    }
  }
`

export const RefreshSession = () => {
  const { handleTokens } = useHandleAuthTokens()
  const [refreshToken] = useMutation(REFRESH_TOKEN)
  const existingToken = getToken()
  const { member: user } = useStytchMember()
  const { session } = useStytchMemberSession()
  const { logout } = useLogout()
  const { search, pathname } = useLocation()
  const onLoginAgain = async () => {
    await logout()
  }

  useEffect(() => {
    let interval
    if (user?.member_id) {
      const msTilSessionExpires = moment(session?.expires_at)?.diff(
        moment(),
        'ms'
      )
      const refreshRate =
        msTilSessionExpires && msTilSessionExpires * 0.8

      const refresh = async () => {
        if (session) {
          const res = await refreshToken()
          handleTokens(
            res?.data?.refreshSession?.token,
            res?.data?.refreshSession?.session
          )
        }
      }

      if (refreshRate > 0) {
        interval = setInterval(refresh, refreshRate)
      }
    } else if (interval) {
      console.log('cleared refresh')
      clearInterval(interval)
    }

    return () => interval && clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, session])

  const params = new URLSearchParams(search)

  const isSessionExpired =
    params.get('expired') === 'true' || (!session && existingToken)

  return isSessionExpired &&
    pathname !== '/agency/login' &&
    pathname !== '/agency/onboarding' ? (
    <SessionTimeoutPopup
      isOpen={isSessionExpired}
      onLoginAgain={onLoginAgain}
    />
  ) : (
    <></>
  )
}
