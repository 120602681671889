/** @jsxImportSource theme-ui */

import React from 'react'
import { Box } from 'theme-ui'

function Timeline({ children, finalColor, sx }) {
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      {/* Vertical line */}
      <Box
        sx={{
          position: 'absolute',
          left: '15px',
          top: 1,
          bottom: 0,
          width: '2px',
          bg: 'gray200',
          zIndex: 0
        }}
      />

      {/* Timeline items */}
      <Box sx={{ ml: '40px' }}>
        {React.Children.map(children, (child, index) => {
          const isLast = index === React.Children.count(children) - 1

          return (
            <Box key={index} sx={{ position: 'relative' }}>
              <Box
                sx={{
                  position: 'absolute',
                  left: '-30px',
                  top: '20px',
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  bg: isLast ? finalColor || 'primary' : 'gray200',
                  border: '2px solid',
                  borderColor: 'background'
                }}
              />
              {child}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export default Timeline
