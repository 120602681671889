import { gql, useQuery } from '@apollo/client'
import uniqueId from 'lodash/uniqueId'
import React, { useState } from 'react'
import { CenteredContent } from 'src/app/components'
import CustomSelectInput from 'src/app/components/Forms/CustomSelectInput'
import Button from 'src/app/components/Shared/Elements/Button'
import FormGroup from 'src/app/components/Shared/Elements/FormGroup'
import Label from 'src/app/components/Shared/Elements/Label'
import ModalBody from 'src/app/components/Shared/Elements/ModalBody'
import ModalFooter from 'src/app/components/Shared/Elements/ModalFooter'
import InlineTooltip from 'src/app/components/Shared/InlineTooltip'
import { useConfirm } from 'src/app/hooks/useConfirm'
import Spinner from 'src/images/icons/Spinner'
import { Flex } from 'theme-ui'

const GET_PDF_FIELDS = gql`
  query GetPdfFields($templateFile: String!) {
    getPdfFields(templateFile: $templateFile)
  }
`

const CustomPDFFieldMappingModal = ({
  setValues,
  values,
  allQuestions,
  handleItemChange,
  toggle
}) => {
  const { templateMapping, templateFields = [] } = values

  const { loading, error } = useQuery(GET_PDF_FIELDS, {
    variables: {
      templateFile: values.templateFile
    },
    skip: values.templateFields?.length,
    onCompleted: d => {
      setValues(prev => ({
        ...prev,
        templateFields: d?.getPdfFields
      }))
      setTemplateOptions(
        d?.getPdfFields?.map(o => ({
          label: o.name,
          value: o.name
        }))
      )
    }
  })

  const questionOptions = allQuestions
    .filter(q => !q.archived && q.checked)
    .map(o => ({
      label: o.helpText
        ? `${o.helpText} (${o.title})`
        : o.valueTypeFormat || o.title,
      value: o
    }))

  const [templateOptions, setTemplateOptions] = useState(
    Array.isArray(templateFields)
      ? templateFields?.map(o => ({
          label: o.name,
          value: o.name
        }))
      : []
  )

  const { isConfirmed } = useConfirm()
  function deleteTemplateMapping(field) {
    setValues(prev => {
      const updatedmapping = prev.templateMapping.filter(
        item => item.id !== field.id
      )

      const updated = {
        ...prev,
        templateMapping: [...updatedmapping]
      }
      return updated
    })

    if (field.fieldItemId && field.fieldItemId.value) {
      handleItemChange(
        {
          name: 'templateFieldIds',
          value: undefined,
          itemId: field.fieldItemId.value.id
        },
        { id: field.fieldItemId.value.formFieldId }
      )
    }
  }

  function updateMapping({ key, templateFieldNames }) {
    let fieldItemId
    let templateNames = []
    setValues(prev => ({
      ...prev,
      templateMapping: [
        ...prev.templateMapping.map((r, k) => {
          let updates = {}
          if (key === k) {
            updates = { templateFieldNames }
            templateNames = templateFieldNames?.map(tfn => tfn.value)
            fieldItemId = r.fieldItemId
          }
          return { ...r, ...updates }
        })
      ]
    }))

    if (fieldItemId && fieldItemId.value) {
      handleItemChange(
        {
          name: 'templateFieldIds',
          value: templateNames,
          itemId: fieldItemId.value.id
        },
        { id: fieldItemId.value.formFieldId }
      )
    }
  }

  function updateQuestionMapping({ key, fieldItemId }) {
    let templateNames = []
    setValues(prev => ({
      ...prev,
      templateMapping: [
        ...prev.templateMapping.map((r, k) => {
          let updates = {}
          if (key === k) {
            updates = { fieldItemId }
            templateNames = r.templateFieldNames?.map(
              tfn => tfn.value
            )
          }
          return { ...r, ...updates }
        })
      ]
    }))

    handleItemChange(
      {
        name: 'templateFieldIds',
        value: templateNames,
        itemId: fieldItemId.value.id
      },
      { id: fieldItemId.value.formFieldId }
    )
  }

  function addNewMapping() {
    setValues(prev => ({
      ...prev,
      templateMapping: [
        ...prev.templateMapping,
        {
          id: uniqueId(),
          templateFieldNames: [],
          fieldItemId: null
        }
      ]
    }))
  }

  if (loading) return <Spinner centered />
  if (error) return <div>{error}</div>

  const allMappedPDFFields = templateMapping
    .flatMap(tm => tm.templateFieldNames)
    ?.filter(tfn => tfn)

  return (
    <ModalBody>
      {templateFields && templateFields.length > 0 ? (
        <>
          {templateMapping &&
            templateMapping.length > 0 &&
            templateMapping.map((field, k) => {
              return (
                <Flex
                  sx={{
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    bg: 'gray200',
                    p: 10,
                    marginBottom: 10,
                    flexWrap: 'wrap'
                  }}
                >
                  <FormGroup
                    sx={{
                      flex: 1,
                      padding: '5px 5px 0',
                      minWidth: '200px'
                    }}
                  >
                    <Label htmlFor="FieldItemId">Form Question</Label>
                    <CustomSelectInput
                      key={field.id}
                      name="FieldItemId"
                      value={field.fieldItemId}
                      isClearable
                      onChange={(suggestion, options) => {
                        updateQuestionMapping({
                          fieldItemId: suggestion,
                          key: k
                        })
                      }}
                      placeholder="Select question..."
                      options={questionOptions}
                    />
                  </FormGroup>
                  <FormGroup
                    sx={{
                      flex: 1,
                      padding: '5px 5px 0',
                      minWidth: '200px'
                    }}
                  >
                    <Label htmlFor="TemplateField">
                      PDF Template Fields
                    </Label>
                    <CustomSelectInput
                      key={field.id}
                      name="TemplateField"
                      value={field.templateFieldNames}
                      isClearable
                      isMulti
                      onChange={(suggestion, options) => {
                        updateMapping({
                          templateFieldNames: suggestion,
                          key: k
                        })
                      }}
                      placeholder="Select template field.."
                      options={templateOptions.map(tf => ({
                        ...tf,
                        isDisabled: allMappedPDFFields.some(
                          f => f.value === tf.value
                        )
                      }))}
                    />
                  </FormGroup>

                  <Button
                    disabled={false}
                    onClick={async e => {
                      const res = await isConfirmed(
                        'Are you sure you want to delete this mapping?'
                      )

                      if (!res) {
                        return
                      }

                      deleteTemplateMapping(field)
                    }}
                    variant={'linkDanger'}
                    icon={'trash'}
                  />
                </Flex>
              )
            })}
          <Button icon="plus" onClick={addNewMapping}>
            Add Mapping
          </Button>
        </>
      ) : (
        <CenteredContent>No fields to map</CenteredContent>
      )}
      <InlineTooltip
        tip={
          'Mapping questions to PDF fields is very open and flexible.  Sometimes a question type may not transfer as intended, please test your PDF template and adjust as needed.'
        }
        variant="warning"
        isCloseable={false}
      />
      <ModalFooter>
        <Button onClick={toggle}>Continue</Button>
      </ModalFooter>
    </ModalBody>
  )
}

export default CustomPDFFieldMappingModal
