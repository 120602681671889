import { Box, Flex } from 'theme-ui'
import { ReadFileButton } from '../../Shared/MarkFilesReadBy'

const ViewFileUrlModalContent = ({ file }) => {
  const { name, url } = file
  return (
    <Box sx={{ height: '100%' }}>
      <Flex
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {' '}
        <h3 style={{ marginBottom: '10px' }}>
          Unable to see?{' '}
          <a target="_blank" rel="noopener noreferrer" href={url}>
            Click here to open
          </a>
        </h3>
        <ReadFileButton file={file} />
      </Flex>
      <iframe
        style={{
          height: '100%'
        }}
        width="100%"
        height="100%"
        src={url.replace('watch?v=', 'embed/')}
        frameBorder="0"
        allowFullScreen
        title={name}
      ></iframe>
    </Box>
  )
}

export default ViewFileUrlModalContent
