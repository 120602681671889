/** @jsxImportSource theme-ui */

import { useMemo, useState } from 'react'

import { prettyDate } from 'src/utils'

import { FilePinButton, FileUploadForm } from './DocumentForms'

import { CenteredContent } from '../../../components'

import Spinner from 'src/images/icons/Spinner'
import {
  GET_MY_AGENCY_DOCUMENTS,
  useGetCurrentAgencyContext
} from '../../../hooks/queries'
import { Flex, Box } from 'theme-ui'
import ModalButton from '../../../components/Shared/ModalButton'

import { useMutation, useQuery } from '@apollo/client'

import CustomSelectInput from '../../../components/Forms/CustomSelectInput'

import { Fragment } from 'react'

import cloneDeep from 'lodash/cloneDeep'
import last from 'lodash/last'
import uniqBy from 'lodash/uniqBy'

import { useToast } from 'src/components/toasts'
import { RolesOnly } from 'src/app/services/auth'

import { ARCHIVE_ONE_FILE } from 'src/app/hooks/mutations'
import LabelBadge from '../../Shared/LabelBadge'
import Button from '../../Shared/Elements/Button'
import ListGroup from '../../Shared/Elements/ListGroup'
import ListGroupItem from '../../Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../../Shared/Elements/ListGroupItemHeading'

import ModalFooter from '../../Shared/Elements/ModalFooter'
import Input from '../../Shared/Elements/Input'

import Elipses from '../../Shared/Elipses'
import Icon from '../../Shared/Icon'
import DocumentViewerModal from './DocumentViewerModal'
import { OrderByButtons } from '../../Shared/OrderByButtons'
import ViewFileUrlModalContent from './ViewFileUrlModalContent'

const SearchContainer = ({
  refetch,
  loading,
  searchState,
  setSearchState,
  onSearch,
  currentFilters
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const categories = currentAgency.fileCategories || []

  const handleSearch = e => {
    e.preventDefault()
    e.stopPropagation()
    onSearch()
  }

  return (
    <Flex
      as={'form'}
      onSubmit={handleSearch}
      sx={{
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        pb: 20
      }}
    >
      <Flex sx={{ flexWrap: 'wrap', alignItems: 'center' }}>
        <Box
          sx={{
            minWidth: '300px',
            pr: 20,
            pb: 10
          }}
        >
          <Input
            placeholder="Search by file category or name."
            onChange={e =>
              setSearchState(prev => ({
                ...prev,
                search: e.target.value
              }))
            }
            value={searchState.search}
          />
        </Box>
        <Box
          sx={{
            minWidth: '300px',
            pr: 20,
            pb: 10
          }}
        >
          <CustomSelectInput
            isMulti={true}
            getOptionValue={v => v.id}
            getOptionLabel={v => v.name}
            value={searchState.fileCategories || []}
            onChange={selected => {
              setSearchState(prev => ({
                ...prev,
                fileCategories: selected
              }))
            }}
            options={categories}
          />
        </Box>
        <Box
          sx={{
            pr: 20
          }}
        >
          <Input
            type="checkbox"
            style={{ cursor: 'pointer' }}
            id={'showArchived'}
            name={'showArchived'}
            checked={searchState.showArchived}
            readOnly
            label={'Include Archived'}
            onClick={() => {
              setSearchState(prev => ({
                ...prev,
                showArchived: !prev.showArchived
              }))
            }}
          />
        </Box>
        <Box
          sx={{
            minWidth: '300px',
            pr: 20,
            pb: 10
          }}
        >
          <Button variant="primary" sx={{ minWidth: 100 }}>
            {loading || 'Search'}
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

const DocumentList = ({
  selectText,
  hideEdit,
  onSelect,
  onDeselect,
  selectedFiles,
  pinnedOnly,
  submissionId,
  compactView,
  formFieldItemResponseId,
  take,
  hideSearch,
  emptyHeight,
  emptyState,
  hidePin,
  hideCreateButton,
  defaultSort = 'newest'
}) => {
  const [sort, setSort] = useState(defaultSort)
  const [searchState, setSearchState] = useState({
    showArchived: false,
    search: '',
    fileCategories: []
  })

  const formFieldItemResponseWhere = formFieldItemResponseId
    ? { id: { equals: formFieldItemResponseId } }
    : null

  const buildQueryVars = (currentSort, includeSearch = false) => {
    let filters = {
      ...(pinnedOnly && {
        pinned: {
          equals: true
        }
      }),
      submission: submissionId
        ? { id: { equals: submissionId } }
        : null,
      formFieldItemResponse: formFieldItemResponseWhere,
      formFieldItemResponseMulti: formFieldItemResponseWhere
    }

    if (includeSearch) {
      if (searchState.fileCategories?.length) {
        filters.fileCategories = {
          some: {
            id: {
              in: searchState.fileCategories.map(fc => fc.id)
            }
          }
        }
      }

      if (searchState.search) {
        filters.OR = [
          {
            name: {
              mode: 'insensitive',
              contains: searchState.search
            }
          },
          {
            fileCategories: {
              some: {
                name: {
                  mode: 'insensitive',
                  contains: searchState.search
                }
              }
            }
          }
        ]
      }

      if (searchState.showArchived) {
        filters.archived = {}
      }
    }

    return {
      take: take || 10,
      orderBy: {
        ...(currentSort === 'newest' && {
          createdAt: 'desc'
        }),
        ...(currentSort === 'az' && {
          name: 'asc'
        }),
        ...(currentSort === 'za' && {
          name: 'desc'
        })
      },
      where: filters
    }
  }

  const {
    data,
    refetch,
    loading,
    networkStatus,
    fetchMore
  } = useQuery(GET_MY_AGENCY_DOCUMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: buildQueryVars(defaultSort, false)
  })

  const handleSearch = () => {
    refetch(buildQueryVars(sort, true))
  }

  const handleSort = newSort => {
    setSort(newSort)
    refetch(buildQueryVars(newSort, false))
  }

  return (
    <Fragment>
      {!hideSearch && (
        <SearchContainer
          refetch={refetch}
          loading={loading && <Spinner white />}
          searchState={searchState}
          setSearchState={setSearchState}
          onSearch={handleSearch}
        />
      )}
      {!hideCreateButton && (
        <Flex
          sx={{
            gap: '20px',
            mb: '10px',
            justifyContent: 'space-between'
          }}
        >
          <OrderByButtons setSort={handleSort} sort={sort} />
          <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
            <ModalButton
              modalHeader="New Document"
              buttonProps={{
                variant: 'primary'
              }}
              buttonLabel="New Document +"
            >
              {({ toggle }) => {
                return (
                  <FileUploadForm
                    onFinish={() => {
                      toggle()
                    }}
                  />
                )
              }}
            </ModalButton>
          </RolesOnly>
        </Flex>
      )}
      {data && data.myAgenciesFiles.length > 0 ? (
        <ListGroup>
          {data.myAgenciesFiles.map((f, index) => (
            <DocumentItem
              refetch={refetch}
              onSelect={onSelect}
              onDeselect={onDeselect}
              selectedFiles={selectedFiles}
              compactView={compactView}
              vars={buildQueryVars(sort, true)}
              selectText={selectText}
              hideEdit={hideEdit}
              hidePin={hidePin}
              key={index}
              {...f}
            />
          ))}
        </ListGroup>
      ) : (
        emptyState || (
          <CenteredContent
            variant="card"
            bc="backgound"
            height={emptyHeight || '200px'}
          >
            {loading && <Spinner />}
            No documents
          </CenteredContent>
        )
      )}
      {data &&
        data.myAgenciesFiles.length > 0 &&
        data.myAgenciesFiles.length % 10 === 0 && (
          <Flex sx={{ p: 10, width: '100%' }}>
            <Button
              variant="primary"
              style={{ margin: 'auto' }}
              disabled={loading || networkStatus === 3}
              icon={loading || networkStatus === 3 ? 'spinner' : ''}
              onClick={() =>
                fetchMore({
                  variables: {
                    cursor: {
                      id: data && last(data.myAgenciesFiles).id
                    },
                    skip: 1
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev

                    return Object.assign({}, prev, {
                      myAgenciesFiles: uniqBy(
                        [
                          ...prev.myAgenciesFiles,
                          ...fetchMoreResult.myAgenciesFiles
                        ],
                        function(e) {
                          return e.id
                        }
                      )
                    })
                  }
                })
              }
            >
              Load more
            </Button>
          </Flex>
        )}
    </Fragment>
  )
}

export const DocumentItem = ({
  onSelect,
  onDeselect,
  selectedFiles,
  refetch,
  selectText,
  hideEdit,
  archiveOneFile,
  updating,
  hidePin,
  vars,
  compactView,
  ...f
}) => {
  const [isOpen, setModalOpen] = useState(false)

  const loading = false

  const DocumentModalMemo = useMemo(
    () => (
      <DocumentViewerModal
        f={f}
        isOpen={isOpen}
        setModalOpen={setModalOpen}
        secureFileUrl={f.secureFileUrl}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOpen]
  )
  const categoryBadges = [
    ...(f.fileType
      ? [
          {
            name: f.fileType.split('.')
              ? f.fileType.split('.').pop()
              : f.fileType,
            color: null
          }
        ]
      : [
          {
            name: 'External Link'
          }
        ]),
    ...(f.fileCategories ? f.fileCategories : [])
  ].map((c, k) => (
    <LabelBadge
      color={c.color}
      text={c.name}
      key={k}
      styles={{
        fontSize: 12,
        padding: '5px',
        marginRight: '5px'
      }}
    />
  ))

  const isSelected =
    onSelect &&
    selectedFiles &&
    selectedFiles.some(sf => sf.id === f.id)
  return (
    <ListGroupItem
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'

        // flexWrap: 'wrap'
      }}
      compactView={compactView}
    >
      <Box
        sx={{
          flex: 1
        }}
      >
        {!compactView && (
          <Flex
            sx={{ mb: 10, flexWrap: 'wrap', alignItems: 'center' }}
          >
            {categoryBadges}
          </Flex>
        )}
        <Flex
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            flex: 1
          }}
        >
          <Flex
            sx={{
              gap: '10px',
              flexWrap: 'wrap'
            }}
          >
            {f.secureFileUrl && isImageFileType(f.fileType) && (
              <img
                width={60}
                height={60}
                sx={{
                  width: '60px',
                  height: '60px'
                }}
                src={f.secureFileUrl}
                alt={f.name}
              />
            )}
            <Box
              sx={{
                flex: 1
              }}
            >
              {f.url ? (
                <ModalButton
                  buttonProps={{
                    variant: 'link',
                    sx: {
                      padding: '0px'
                    }
                  }}
                  contentHeight={'92vh'}
                  href={f.url}
                  CustomButtonComponent={props => (
                    <ListGroupItemHeading
                      sx={{
                        cursor: 'pointer',
                        alignItems: 'center',
                        display: 'flex',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-wrap'
                      }}
                      compactView={compactView}
                      {...props}
                    >
                      {f.name} - url
                      <Icon icon="OpenTab" width={30} height={18} />
                    </ListGroupItemHeading>
                  )}
                  contentSize={'lg'}
                >
                  {() => {
                    return <ViewFileUrlModalContent file={f} />
                  }}
                </ModalButton>
              ) : (
                <ListGroupItemHeading
                  sx={{
                    alignItems: 'flex-start',
                    cursor: 'pointer',
                    display: 'flex'
                  }}
                  onClick={async () => {
                    setModalOpen(true)
                  }}
                  disabled={loading}
                >
                  <Elipses mw={'400px'}>{f.name}</Elipses>
                  <Box
                    sx={{
                      minWidth: '30px',
                      minHeight: '20px'
                    }}
                  >
                    <Icon icon="download" width={30} height={20} />
                  </Box>
                </ListGroupItemHeading>
              )}

              <Box
                sx={{
                  fontSize: compactView && '12px'
                }}
              >
                <strong>{f.uploadedBy.name}</strong> on{' '}
                {prettyDate(f.createdAt)}
              </Box>
            </Box>
            {isOpen && DocumentModalMemo}
          </Flex>
        </Flex>
      </Box>
      <Box>
        <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
          {!hideEdit && (
            <Flex
              sx={{
                width: ['100%', null, null, 'auto'],
                py: [2, 2, 2, '0px']
              }}
            >
              {!hidePin && (
                <FilePinButton fileId={f.id} isPinned={f.pinned} />
              )}

              <ModalButton
                buttonLabel="Edit"
                buttonProps={{
                  variant: 'link',
                  style: {
                    display: 'inline-block'
                  }
                }}
              >
                {({ toggle }) => {
                  return (
                    <FileUploadForm
                      onFinish={() => {
                        toggle()
                      }}
                      file={f}
                    />
                  )
                }}
              </ModalButton>
              <ArchiveDocumentButton f={f} vars={vars} />
            </Flex>
          )}
        </RolesOnly>
      </Box>
      {onSelect && (
        <Box sx={{ mb: 10, mr: 20 }}>
          <Button
            variant={isSelected ? 'success' : 'primary'}
            onClick={e =>
              isSelected && onDeselect
                ? onDeselect(e, f)
                : onSelect(e, f)
            }
            sx={{
              display: 'inline-block'
            }}
          >
            {selectText || isSelected ? 'Remove' : 'Attach'}
          </Button>
        </Box>
      )}
    </ListGroupItem>
  )
}

const ArchiveDocumentButton = ({ f, vars }) => {
  const { add } = useToast()
  const [archiveOneFile, { loading: updating }] = useMutation(
    ARCHIVE_ONE_FILE,
    {
      update: (store, { data: { archiveOneFile } }) => {
        // Read the data from our cache for this query.
        const data = cloneDeep(
          store.readQuery({
            query: GET_MY_AGENCY_DOCUMENTS,
            variables: vars
          })
        )

        if (archiveOneFile.archived) {
          data.myAgenciesFiles = data.myAgenciesFiles.filter(
            d => d.id !== archiveOneFile.id
          )
        }

        // Write our data back to the cache.
        store.writeQuery({
          query: GET_MY_AGENCY_DOCUMENTS,
          data: { ...data },
          variables: vars
        })
      }
    }
  )

  const isArchived = f.archived

  return (
    <ModalButton
      modalHeader={'Are you sure you want to archive this file?'}
      buttonProps={{
        variant: 'link',
        icon: isArchived ? 'restore' : 'trash',
        iconColor: isArchived ? 'success' : 'danger',
        sx: {
          color: isArchived ? 'success' : 'danger'
        }
      }}
    >
      {({ toggle }) => {
        return (
          <ModalFooter onClose={toggle}>
            <Button
              onClick={async () => {
                const res = await archiveOneFile({
                  variables: {
                    id: f.id
                  }
                })

                if (res && !res.errors) {
                  add({
                    content: 'File archived.',
                    color: 'success'
                  })
                }
                toggle()
              }}
              variant={isArchived ? 'success' : 'danger'}
              disabled={updating}
              icon={updating ? 'spinner' : ''}
            >
              {isArchived ? 'Unarchive' : 'Yes, archive'}
            </Button>{' '}
          </ModalFooter>
        )
      }}
    </ModalButton>
  )
}

function isImageFileType(fileType) {
  const imageFileTypes = ['png', 'jpeg', 'jpg']
  return imageFileTypes.some(type => fileType?.includes(type))
}

export default DocumentList
