import uniqueId from 'lodash/uniqueId'
import {
  checkContactInfoHasValue,
  handleContactInfosSet
} from 'src/utils/handleContactInfosSet'

export const formAlertCheckOptions = [
  { label: 'Yes', value: 'boolean.true' },
  { label: 'No', value: 'boolean.false' }
]

//Configuration and options for all forms features. This can be moved to server at some point.
export const fieldItemConfig = [
  {
    value: 'boolean',
    pulseValue: 'BOOLEAN',
    title: 'Yes / No',
    trendable: true,
    filterable: true,
    operators: ['exists', 'not_exists', 'is_true', 'is_false'],
    canBeStatus: true,
    canBeSummaryAnswer: true
  },
  {
    value: 'text',
    pulseValue: 'TEXT',
    title: 'Text',
    canBeValidated: true,
    canBeMulti: true,
    canHaveOther: true,
    trendable: true,
    filterable: true,
    canBeStatus: true,
    canBeSummaryAnswer: true,
    operators: [
      'exists',
      'not_exists',
      'equals',
      'not_equal',
      'contains',
      'not_contains'
    ],
    valueTypeFormats: ['email', 'phone']
  },
  {
    value: 'text',
    pulseValue: null,
    title: 'Signature',
    canBeValidated: false,
    canBeMulti: false,
    canHaveOther: false,
    trendable: false,
    filterable: true,
    canBeSummaryAnswer: false,
    canBeStatus: false,
    operators: ['exists', 'not_exists'],
    valueTypeFormat: 'signature',
    moduleRequired: 'medicalForms'
  },
  {
    value: 'number',
    pulseValue: 'NUMBER',
    title: 'Number',
    canBeValidated: true,
    canBeMulti: true,
    canBeSummaryAnswer: true,
    canHaveOther: true,
    trendable: true,
    filterable: true,
    canBeStatus: true,
    operators: [
      'equals',
      'not_equal',
      'exists',
      'not_exists',
      'greater_than',
      'less_than',
      'greater_than_equal_to',
      'less_than_equal_to'
    ],
    valueTypeFormats: ['duration']
  },
  {
    value: 'dateTime',
    pulseValue: 'DATETIME',
    title: 'Date',
    filterable: true,
    canBeStatus: true,
    canBeSummaryAnswer: true,
    operators: ['between']
  },
  {
    value: 'file',
    pulseValue: 'FILE',
    title: 'File',
    isFileType: true,
    canBeSummaryAnswer: false,
    // File questions always allow multiple files
    canBeMulti: true,
    filterable: true,
    operators: ['exists', 'not_exists']
  },
  {
    value: 'locations',
    pulseValue: 'LOCATION',
    title: 'Address',
    canBeMulti: true,
    filterable: true,
    trendable: true,
    treatAsRelation: true,
    canBeSummaryAnswer: true,
    operators: ['contains'],
    valueTypeFormat: 'address'
  },
  {
    value: 'positions',
    pulseValue: 'POSITION',
    title: 'Resource/Location',
    isNodeType: true,
    treatAsRelation: true,
    canBeValidated: true,
    canBeMulti: true,
    trendable: true,
    filterable: true,
    canBeSummaryAnswer: true,
    canUseAssignment: true,
    operators: ['exists', 'contains', 'not_contains', 'not_exists'],
    valueTypeFormats: ['locations', 'resources', 'landmarks']
  },
  {
    value: 'users',
    pulseValue: 'USER',
    title: 'Personnel',
    isNodeType: true,
    treatAsRelation: true,
    canBeValidated: true,
    canBeMulti: true,
    canBeSummaryAnswer: true,
    trendable: true,
    filterable: true,
    canUseAssignment: true,
    operators: ['exists', 'contains', 'not_contains', 'not_exists']
  },
  {
    value: 'assets',
    pulseValue: 'ASSET',
    title: 'Asset',
    isNodeType: true,
    treatAsRelation: true,
    canBeValidated: true,
    canBeSummaryAnswer: true,
    canBeMulti: true,
    trendable: true,
    filterable: true,
    operators: ['exists', 'not_exists', 'contains', 'not_contains']
  }
]

export function getFieldConfig(fieldItem) {
  if (!fieldItem) return null

  const specialType = fieldItemConfig.find(
    dt =>
      dt.value === fieldItem.type &&
      dt.valueTypeFormat === fieldItem.valueTypeFormat
  )

  if (specialType) {
    return specialType
  }

  return (
    fieldItem &&
    fieldItemConfig.find(dt => dt.value === fieldItem.type)
  )
}

export const relationFieldItemTypes = fieldItemConfig
  .filter(dt => dt.treatAsRelation)
  .map(dt => dt.value)

export const statusFieldItemTypes = fieldItemConfig
  .filter(dt => dt.canBeStatus)
  .map(dt => dt.value)

export const getDefaultFieldItems = () =>
  fieldItemConfig.map(dt => ({
    id: uniqueId(),
    type: dt.value,
    title: dt.title,
    checked: false
  }))

export function mapFieldValuesToCreate(fields, generateId) {
  return {
    create: fields
      .filter(f => !f.archived)
      .map(({ id, order, statusFor, existing, ...field }, k) => {
        return {
          ...field,

          order: order || k,
          fieldItems: {
            create: field.fieldItems
              .filter(fi => fi.checked)
              .map(
                ({
                  type,
                  contactInfos,
                  check = {},
                  required,
                  helpText,
                  summaryAnswer,
                  allowedValues = [],
                  allowMultipleAnswers,
                  allowOther,
                  createdAt,
                  autoUpdates,
                  valueTypeFormat,
                  useAssignment,
                  templateFieldIds = [],
                  // Need an id so we can properly connect the nested automations... This has to be deterministic and random, collisions can cause issues
                  id
                }) => {
                  const autoUpdatesToCreate = autoUpdates?.filter(
                    au => au.active
                  )
                  return {
                    ...(generateId && { id }),
                    ...(createdAt && { createdAt }),
                    ...(autoUpdatesToCreate?.length > 0 && {
                      autoUpdates: {
                        create: autoUpdatesToCreate.map(au => ({
                          relationFromFormQuestion: {
                            connect: {
                              id: au?.relationFromFormQuestion?.id
                            }
                          },
                          updatesStatus: {
                            connect: {
                              id: au?.updatesStatus?.id
                            }
                          },
                          active: au?.active
                        }))
                      }
                    }),

                    required: required,
                    helpText: helpText,
                    summaryAnswer,
                    templateFieldIds: {
                      set: templateFieldIds?.map(tf => tf.id || tf)
                    },
                    valueTypeFormat: valueTypeFormat || null,
                    allowOther: allowOther,
                    useAssignment: useAssignment,
                    allowMultipleAnswers: allowMultipleAnswers,
                    allowedValues: {
                      set: allowedValues.map(av => av.id || av)
                    },
                    ...(checkContactInfoHasValue(contactInfos) && {
                      formAlert: {
                        create: {
                          check: check.value,
                          ...handleContactInfosSet(contactInfos)
                        }
                      }
                    }),
                    type
                  }
                }
              )
          }
        }
      })
  }
}

export function mapFieldValuesToUpdateForm(fields, orderChanged) {
  const fieldsToUpdate = fields
    .filter(
      f =>
        f.existing &&
        (orderChanged ||
          f.updated ||
          f.fieldItems.some(fi => fi.updated))
    )
    .map(f => ({
      ...f,
      order: fields.findIndex(fi => fi.id === f.id)
    }))

  const fieldsToCreate = fields
    .filter(f => !f.existing)
    .map(f => ({
      ...f,

      order: fields.findIndex(fi => fi.id === f.id)
    }))

  return {
    ...(fieldsToCreate.length > 0 &&
      mapFieldValuesToCreate(fieldsToCreate, false)),
    ...(fieldsToUpdate && {
      update: fieldsToUpdate.map((f, k) => {
        const archiveField = f.archived
        const fieldItems = f.fieldItems.filter(
          fi => fi.updated && fi.existing
        )
        const fieldItemsToCreate = f.fieldItems.filter(
          fi => fi.existing === false && fi.checked
        )

        return {
          where: { id: f.id },
          data: {
            archived: archiveField,
            order: f.order,
            title: f.title || '',

            ...((fieldItems.length > 0 ||
              fieldItemsToCreate.length > 0) && {
              fieldItems: {
                ...(fieldItemsToCreate.length > 0 && {
                  create: fieldItemsToCreate.map(
                    ({
                      contactInfos,
                      check = {},
                      allowedValues = [],
                      allowMultipleAnswers,
                      allowOther,
                      createdAt,
                      autoUpdates,
                      useAssignment = false,
                      templateFieldIds = [],
                      ...fi
                    }) => {
                      const autoUpdatesToCreate = autoUpdates?.filter(
                        au => au.active
                      )

                      return {
                        type: fi.type,
                        required: fi.required,
                        helpText: fi.helpText,
                        summaryAnswer: fi.summaryAnswer,
                        valueTypeFormat: fi.valueTypeFormat || null,
                        useAssignment,
                        templateFieldIds: {
                          set: templateFieldIds?.map(
                            tf => tf.id || tf
                          )
                        },
                        ...(autoUpdatesToCreate?.length > 0 && {
                          autoUpdates: {
                            create: autoUpdatesToCreate.map(au => ({
                              relationFromFormQuestion: {
                                connect: {
                                  id: au?.relationFromFormQuestion?.id
                                }
                              },
                              updatesStatus: {
                                connect: {
                                  id: au?.updatesStatus?.id
                                }
                              },
                              active: au?.active
                            }))
                          }
                        }),
                        allowOther: allowOther,
                        ...(createdAt && { createdAt }),
                        allowMultipleAnswers: allowMultipleAnswers,
                        allowedValues: {
                          set: allowedValues.map(av => av.id || av)
                        },
                        ...(checkContactInfoHasValue(
                          contactInfos
                        ) && {
                          formAlert: {
                            create: {
                              check: check.value,
                              ...handleContactInfosSet(contactInfos)
                            }
                          }
                        })
                      }
                    }
                  )
                }),
                ...(fieldItems.length > 0 && {
                  update: fieldItems.map(
                    ({
                      contactInfos,
                      check = {},
                      allowedValues = [],
                      allowMultipleAnswers,
                      allowOther,
                      createdAt,
                      useAssignment,
                      templateFieldIds = [],
                      ...fi
                    }) => {
                      const noContacts =
                        !contactInfos ||
                        contactInfos?.length === 0 ||
                        contactInfos?.every(c => c?.length < 1)

                      const autoUpdatesToUpdate = fi.autoUpdates?.filter(
                        au => !au.newAutomation
                      )
                      const autoUpdatesToCreate = fi.autoUpdates?.filter(
                        au => au.newAutomation
                      )

                      return {
                        where: { id: fi.id },
                        data: {
                          archived: !fi.checked,
                          required: fi.required,
                          helpText: fi.helpText,
                          summaryAnswer: fi.summaryAnswer,
                          valueTypeFormat: fi.valueTypeFormat || null,
                          allowOther: allowOther,
                          useAssignment,
                          ...(createdAt && { createdAt }),
                          allowMultipleAnswers: allowMultipleAnswers,
                          allowedValues: {
                            set: allowedValues.map(av => av.id || av)
                          },
                          templateFieldIds: {
                            set: templateFieldIds.map(
                              tf => tf.id || tf
                            )
                          },
                          autoUpdates: {
                            ...(autoUpdatesToCreate?.length > 0 && {
                              create: autoUpdatesToCreate.map(au => ({
                                relationFromFormQuestion: {
                                  connect: {
                                    id:
                                      au?.relationFromFormQuestion?.id
                                  }
                                },
                                updatesStatus: {
                                  connect: {
                                    id: au?.updatesStatus?.id
                                  }
                                },
                                active: au?.active
                              }))
                            }),
                            ...(autoUpdatesToUpdate?.length > 0 && {
                              update: autoUpdatesToUpdate?.map(
                                au => ({
                                  where: {
                                    id: au.id
                                  },
                                  data: {
                                    active: au.active
                                  }
                                })
                              )
                            })
                          },
                          ...(!noContacts ||
                          checkContactInfoHasValue(contactInfos)
                            ? {
                                formAlert: {
                                  upsert: {
                                    create: {
                                      check: check && check.value,
                                      ...handleContactInfosSet(
                                        contactInfos
                                      )
                                    },
                                    update: {
                                      check: check && check.value,
                                      ...handleContactInfosSet(
                                        contactInfos
                                      )
                                    }
                                  }
                                }
                              }
                            : {
                                ...(fi.formAlert && {
                                  formAlert: {
                                    delete: true
                                  }
                                })
                              })
                        }
                      }
                    }
                  )
                })
              }
            })
          }
        }
      })
    })
  }
}

export function getDefaultValues({
  form,
  defaultTags,
  defaultField
}) {
  let templateMapping = []
  form.fields &&
    form.fields
      .filter(f => !f.archived)
      .map(f => {
        f.fieldItems
          .filter(fi => !fi.archived)
          .map(fi => {
            if (fi.templateFieldIds?.length > 0) {
              templateMapping.push({
                id: uniqueId(),
                templateFieldNames: fi.templateFieldIds.map(tfid => ({
                  value: tfid,
                  label: tfid
                })),
                fieldItemId: {
                  value: fi,
                  label: `${fi.helpText ||
                    fi.valueTypeFormat ||
                    fi.title ||
                    fi.type}`
                }
              })
            }
          })
      })

  return {
    ...(defaultTags && { fileCategories: defaultTags }),

    ...form,
    ...(form?.supervisorReviewConfig && {
      supervisorReviewConfig: {
        ...form?.supervisorReviewConfig,
        enabled: true,
        existing: true
      }
    }),
    ...(form && {
      ...form.formAlert
    }),
    fields: form?.fields
      ? form.fields.map(f => ({
          ...f,
          ...f.formAlert,

          existing: true,
          //combine the default field items with the existing field items
          fieldItems: f.fieldItems
            ? f.fieldItems.map(fi => {
                let defaultItem = getDefaultFieldItems().find(
                  di => di.type === fi.type
                )

                const { formAlert, ...exs } = fi
                const currentFi = {
                  ...(formAlert && {
                    ...formAlert,
                    //map check to the strucuture needed by react-select
                    check:
                      formAlert.check &&
                      formAlertCheckOptions.find(
                        c => c.value === formAlert.check
                      )
                  }),
                  ...exs,
                  existing: true,
                  checked: fi.archived === false ? true : false
                }
                return {
                  ...defaultItem,
                  id: uniqueId(),
                  existing: false,
                  checked: false,
                  ...fi,
                  ...currentFi
                }
              })
            : []
        }))
      : [defaultField],
    templateMapping:
      templateMapping?.length > 0
        ? templateMapping
        : [
            {
              id: uniqueId(),
              templateFieldNames: [],
              fieldItemId: null
            }
          ]
  }
}
