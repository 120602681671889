/** @jsxImportSource theme-ui */
import { Fragment } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { SUBMIT_FORM } from 'src/app/hooks/mutations'
import {
  GET_FORM,
  useGetLatestSupervisorReviews
} from 'src/app/hooks/queries'
import { useToast } from 'src/components/toasts'
import Spinner from 'src/images/icons/Spinner'
import { theme } from 'src/styles'
import { getContrastColor } from 'src/utils/getContrastColor'

import { cleanGraphQLError } from '../Forms/FormError'

import { mapValuesToSubmitForm } from '../Operations/Forms/formSubmitRenderer/dataMapping'
import { FormResponseSubmitRenderer } from '../Operations/Forms/formSubmitRenderer/FormResponseSubmitRenderer'
import { FormSubmitRendererModalButton } from '../Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'
import Button from '../Shared/Elements/Button'
import ModalBody from '../Shared/Elements/ModalBody'
import ModalFooter from '../Shared/Elements/ModalFooter'

import InlineTooltip from '../Shared/InlineTooltip'
import ModalButton from '../Shared/ModalButton'
import { Box, Flex } from 'theme-ui'
import ListGroupItem from '../Shared/Elements/ListGroupItem'
import Badge from '../Shared/Elements/Badge'
import { Overline } from '../Shared'
import { prettyDate } from 'src/utils'
import Timeline from '../Shared/VerticalTimeline'

const READY_FOR_REVIEW = 'Ready for Review'

function getReviewButtonProps(currentStatusColor) {
  const contrastColor = getContrastColor(
    theme.colors[currentStatusColor] || currentStatusColor,
    3,
    'white'
  )

  return {
    iconColor: contrastColor,

    sx: {
      bg: currentStatusColor,
      color: contrastColor,
      border: 'none',
      ':hover': {
        bg: currentStatusColor,
        opacity: 0.8
      }
    },
    size: 'sm'
  }
}

export const SupervisorReviewModalButton = ({
  formId,
  submissionId,
  currentStatus,
  currentStatusColor,
  callId,
  submission,
  refetchQueries
}) => {
  return (
    <>
      <FormSubmitRendererModalButton
        refetchQueries={refetchQueries}
        submitToastText="Review Submitted"
        preFormRenderer={
          <SupervisorReviewPreview
            formId={formId}
            submissionId={submissionId}
            submission={submission}
          />
        }
        hideTitle
        formId={formId}
        submissionId={submissionId}
        modalButtonProps={{
          modalHeader: `Supervisor Review ${callId && `(${callId})`}`,
          buttonLabel: currentStatus,
          buttonProps: {
            ...getReviewButtonProps(currentStatusColor)
          }
        }}
      />
    </>
  )
}

const SupervisorReviewPreview = ({
  submissionId,
  emptyState,
  formId,
  noEdit,
  refetchQueries,
  submission
}) => {
  const { data, error } = useGetLatestSupervisorReviews(
    submissionId,
    {
      skip: !submissionId
    }
  )

  const lastReviews = data

  if (!data && submissionId)
    return <Spinner containerHeight={200} centered />
  if (error) return <div>{cleanGraphQLError(error)}</div>
  if (!lastReviews?.length) return emptyState || <Fragment />

  const defaultReviewState = lastReviews[0]?.defaultReviewState
  return (
    <Box
      sx={{
        py: 20
      }}
    >
      <h6>Past Reviews</h6>
      <Timeline
        finalColor={
          lastReviews[lastReviews.length - 1]?.reviewStatusColor
        }
      >
        {submission && (
          <ListGroupItem
            sx={{
              py: '10px',
              px: 3
            }}
          >
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                flexWrap: 'wrap'
              }}
            >
              <Flex
                sx={{
                  gap: '5px',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap'
                }}
              >
                <Badge
                  {...getReviewButtonProps(
                    defaultReviewState?.color || 'primary'
                  )}
                >
                  {defaultReviewState?.state}
                </Badge>
                <Overline sx={{ mb: '0px' }}>
                  by {submission?.reporter?.name} on{' '}
                  {prettyDate(submission?.createdAt)}
                </Overline>
              </Flex>
              <Button
                sx={{
                  px: '0px'
                }}
                size="sm"
                variant="link"
              >
                View Details
              </Button>
            </Flex>
          </ListGroupItem>
        )}
        {lastReviews?.map((lr, k) => {
          return (
            <ListGroupItem
              sx={{
                py: '10px',
                px: 3
              }}
              key={k}
            >
              <ModalButton
                CustomButtonComponent={({ onClick }) => (
                  <Flex
                    sx={{
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      flexWrap: 'wrap'
                    }}
                    onClick={onClick}
                  >
                    <Flex
                      sx={{
                        gap: '5px',
                        alignItems: 'flex-start',
                        flexWrap: 'wrap'
                      }}
                    >
                      <Badge
                        {...getReviewButtonProps(
                          lr?.reviewStatusColor || 'primary'
                        )}
                      >
                        {lr?.reviewStatus}
                      </Badge>
                      <Overline sx={{ mb: '0px' }}>
                        by {lr?.submittedBy?.name} on{' '}
                        {prettyDate(lr?.createdAt)}
                      </Overline>
                    </Flex>
                    <Button
                      sx={{
                        px: '0px'
                      }}
                      size="sm"
                      variant="link"
                    >
                      View Details
                    </Button>
                  </Flex>
                )}
              >
                {({ toggle }) => {
                  return (
                    <FormResponseSubmitRenderer
                      key={k}
                      includeReporting={false}
                      formId={formId}
                      formResponseId={lr.id}
                      hideLinkToIncident
                      tableView
                      spinnerProps={false}
                      hideEdit={noEdit}
                      refetchQueries={refetchQueries}
                      onFinish={toggle}
                    />
                  )
                }}
              </ModalButton>
            </ListGroupItem>
          )
        })}
      </Timeline>
    </Box>
  )
}

export const RequestSupervisorReview = ({
  currentStatus,
  onFinish,
  submissionId,
  refetchQueries = [],
  submission,
  formId
}) => {
  const { add } = useToast()
  const [submitForm, { loading: submitting }] = useMutation(
    SUBMIT_FORM,
    {
      refetchQueries
    }
  )

  const { data, loading: loadingForm } = useQuery(GET_FORM, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !formId,
    variables: {
      where: { id: formId },
      fieldsWhere: {
        archived: {
          equals: false
        }
      },
      fieldItemsWhere: {
        archived: {
          equals: false
        }
      }
    }
  })
  const form = data?.form

  const requestState = form?.supervisorReviewConfig?.stateOptions?.find(
    stateOption => stateOption.requestReviewState
  )

  const defaultStateOptions = requestState?.state || READY_FOR_REVIEW
  const defaultStateOptionsColor = requestState?.color || 'primary'
  const isRequested = currentStatus === defaultStateOptions

  async function requestReview() {
    try {
      const values = {
        formId: formId,
        submissionId,
        fields: form?.fields
          ?.filter(field => !field.archived)
          .map(field => ({
            ...field,
            fieldItems: field.fieldItems
              .filter(fieldItem => !fieldItem.archived)
              ?.map(fieldItem => ({
                ...fieldItem,
                value:
                  form?.supervisorReviewConfig
                    ?.reviewStatusFieldItemId === fieldItem.id
                    ? defaultStateOptions
                    : fieldItem.value
              }))
          }))
      }

      const mappeddata = mapValuesToSubmitForm(values)

      let res = await submitForm({
        variables: { data: mappeddata }
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content: 'Review Requested'
        })
        onFinish && onFinish()
      } else {
        add({
          content: cleanGraphQLError(res?.errors[0]),
          color: 'danger'
        })
      }
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  const loading = loadingForm

  if (loading) return <Spinner centered />
  return (
    <ModalBody
      sx={{
        pt: '40px'
      }}
    >
      {!formId ? (
        <InlineTooltip
          header={'No supervisor review form configured.'}
          tip={
            'This incident type does not have a supervisor review form configured.  Please contact your administrator.'
          }
          variant="warning"
          isCloseable={false}
        />
      ) : isRequested ? (
        <InlineTooltip
          header={'Request sent'}
          tip={
            'Your review has been requested.  Reach out to your supervisor with any questions or updates.'
          }
          variant="success"
          isCloseable={false}
        />
      ) : (
        <SupervisorReviewPreview
          noEdit
          formId={formId}
          submissionId={
            currentStatus !== READY_FOR_REVIEW && submissionId
          }
          submission={submission}
          emptyState={
            <InlineTooltip
              header={'No Review'}
              tip={
                'This submission has not been reviewed by a supervisor.  Click the button below to request a review.'
              }
              variant="warning"
              isCloseable={false}
            />
          }
        />
      )}

      {!isRequested && formId && (
        <ModalFooter>
          <Button
            variant="primary"
            {...getReviewButtonProps(defaultStateOptionsColor)}
            size="md"
            onClick={requestReview}
            icon={
              submitting ? 'spinner' : isRequested ? 'success' : ''
            }
            disabled={loadingForm || submitting || isRequested}
          >
            {isRequested
              ? 'Review Requested'
              : 'Mark as ' + defaultStateOptions}
          </Button>
        </ModalFooter>
      )}
    </ModalBody>
  )
}

export const RequestSupervisorReviewModalButton = props => {
  return (
    <ModalButton
      buttonProps={getReviewButtonProps(props.currentStatusColor)}
      buttonLabel={props.currentStatus}
      modalHeader="Request Supervisor Review"
      contentSize={'lg'}
    >
      {({ toggle }) => {
        return (
          <RequestSupervisorReview
            {...props}
            formId={props.formId}
            onFinish={() => {
              toggle()
              props.onFinish && props.onFinish()
            }}
          />
        )
      }}
    </ModalButton>
  )
}
