/** @jsxImportSource theme-ui */

import { useEffect } from 'react'
import {
  deleteToken,
  getCookie,
  useHandleAuthTokens
} from '../services/auth'

import Spinner from 'src/images/icons/Spinner'

import { CenteredContent } from '../components'

import Icon from '../components/Shared/Icon'
import Button from '../components/Shared/Elements/Button'
import { useNavigate } from 'react-router'

const SupportLogin = () => {
  const { handleTokens } = useHandleAuthTokens(true)

  const navigate = useNavigate()
  useEffect(() => {
    async function authenticate() {
      try {
        const token = getCookie('supportToken')
        const session = getCookie('supportSession')
        console.log(token, session)
        await handleTokens(token, session)
        deleteToken('supportToken')
        deleteToken('supportSession')
        navigate('/agency')
      } catch (e) {
        console.log(e)
        navigate('/agency/auth-error')
      }
    }

    authenticate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CenteredContent>
      <Spinner />
    </CenteredContent>
  )
}

export default SupportLogin
