import { useEffect } from 'react'
import get from 'lodash/get'
import { Box, Flex } from 'theme-ui'

import { optionalChaining } from 'src/utils'

import CustomSelectInput from './CustomSelectInput'

import Button from '../Shared/Elements/Button'
import FormText from '../Shared/Elements/FormText'

import Icon from '../Shared/Icon'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import useGetTodaysAssignment from 'src/app/hooks/useGetTodaysAssignment'

export const AddResourceInputs = ({
  dispatches,
  mergeValues,
  values,
  useTodaysPosition = true,
  renderHelperText = false
}) => {
  const { user, users, positions } = useGetCurrentAgencyContext()
  const { data } = useGetTodaysAssignment()

  const responders = get(data, 'todaysResponders', [])
  const location = get(data, 'todaysLocation', null)

  useEffect(() => {
    const hasNoResponders = !dispatches || dispatches.length < 1

    if (hasNoResponders) {
      addResponderInstance(true)
    }

    if (useTodaysPosition && responders.length > 0) {
      const locationPosition = location
      const dailyRespondersWithoutPositions = responders.filter(
        i => !i.positionId
      )

      const hasDispatches = dispatches && dispatches[0]

      let otherPositions = hasDispatches
        ? dispatches.filter(i => !i.todaysPosition)
        : []

      const dispatchesThatMatchTodaysResponders = responders.filter(
        r =>
          r.positionId &&
          dispatches?.some(
            d => !d.todaysPosition && d?.unit?.id === r.positionId
          )
      )

      const currentPosition =
        otherPositions[0] && otherPositions[0].unit

      if (dailyRespondersWithoutPositions.length > 0) {
        //If user has set daily without a position, assume that they are the
        //  unit selected in the flow,  remove the original one

        otherPositions.splice(
          0,
          dailyRespondersWithoutPositions.length
        )
      }

      const mergedRespondersandDispatches = [
        //If user has set daily without a position, assume that they
        // are the unit selected in the flow,  use the unit that was already added
        ...responders
          .filter((r, k) => {
            // Check if there is a dispatch that matches the todays responder position, merge these, remove the
            // original respoonder and add below to the dispatches
            // this is only relevant for the user dispatch flow..

            return dispatchesThatMatchTodaysResponders?.length > 0
              ? !dispatchesThatMatchTodaysResponders.find(
                  d => d.positionId === r.positionId
                )
              : true
          })
          .map((i, k) => {
            return {
              id: 'new' + (k + 1),
              updated: true,
              todaysPosition: true,
              unit: i.positionId
                ? {
                    todaysPosition: true,
                    id: i.positionId,
                    name: i.positionName
                  }
                : currentPosition,
              ...(i.responderId && {
                personnel: {
                  todaysPosition: true,
                  id: i.responderId,
                  name: i.responderName
                }
              })
            }
          }),
        // Check if there is a dispatch that matches the todays responder position, merge those
        ...otherPositions?.map(i => {
          const todaysPositionOverride =
            dispatchesThatMatchTodaysResponders &&
            dispatchesThatMatchTodaysResponders.length > 0 &&
            responders?.length > 0 &&
            responders.find(r => i?.unit?.id === r.positionId)

          return {
            ...i,
            ...(todaysPositionOverride && {
              updated: true,
              personnel: {
                todaysPosition: true,
                id: todaysPositionOverride?.responderId,
                name: todaysPositionOverride?.responderName
              }
            })
          }
        })
      ]

      mergeValues(
        {
          ...(locationPosition && {
            location: {
              ...values.location,
              position: {
                value: locationPosition.locationId,
                name: locationPosition.locationName
              }
            }
          }),
          dispatches: mergedRespondersandDispatches
        },
        true
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responders, location])

  const posOpts = positions.filter(p => !p.locationOnly)

  const userOpts = users

  function addResponderInstance(notDirty) {
    mergeValues(
      {
        dispatches: values.dispatches
          ? [
              ...values.dispatches,
              {
                id: 'new' + (values.dispatches.length + 1),
                updated: true
              }
            ]
          : [
              {
                id: 'new' + 1,
                updated: true
              }
            ]
      },
      notDirty
    )
  }

  const updateExisting = (selected, attrKey, currentId) => {
    mergeValues({
      dispatches: values.dispatches
        ? values.dispatches.map((i, k) => {
            const newValue = k === currentId
            if (currentId === (i.id || k)) {
              return {
                ...i,
                updated: newValue ? false : true,
                [attrKey]: {
                  ...selected
                }
              }
            }
            return i
          })
        : []
    })
  }

  const removeExisting = currentId => {
    mergeValues({
      dispatches: values.dispatches
        ? values.dispatches.map((i, k) => {
            if (currentId === (i.id || k)) {
              return {
                ...i,
                removed: true,
                updated: false
              }
            }
            return i
          })
        : []
    })
  }
  return (
    <Box>
      {dispatches &&
        dispatches
          .filter(i => !i.removed)
          .map((i, k) => {
            const unitKey = 'unit' + k
            const personnelKey = 'personnel' + k

            const {
              defaultPersonnel,
              defaultUnit
            } = getDefaultResource({
              users,
              positions,
              values: i
            })

            return (
              <Box key={k}>
                {renderHelperText[k] && renderHelperText[k]}
                <Flex
                  sx={{
                    bg: 'gray100',
                    p: '5px',
                    alignItems: 'flex-start'
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      padding: '10px'
                    }}
                  >
                    {i.personnel && i.personnel.todaysPosition && (
                      <Box
                        sx={{
                          textAlign: 'center',
                          marginTop: '-20px'
                        }}
                      >
                        <FormText>AUTO-FILLED</FormText>
                      </Box>
                    )}
                    <CustomSelectInput
                      options={userOpts}
                      mobile
                      getOptionLabel={i => i.name}
                      getOptionValue={i => i.id}
                      onChange={selected => {
                        return updateExisting(
                          selected,
                          'personnel',
                          i.id || k
                        )
                      }}
                      defaultValue={defaultPersonnel}
                      value={defaultPersonnel}
                      name={personnelKey}
                      label={`Personnel  ${k + 1}`}
                      style={{ zIndex: 1000 }}
                      quickSelectTitle={
                        user?.id &&
                        !i?.personnel?.todaysPosition &&
                        user?.id !== defaultPersonnel?.id &&
                        'Select Me'
                      }
                      quickSelectFunction={opts => {
                        return (
                          opts?.find &&
                          opts?.find(o => {
                            return o.id === user.id
                          })
                        )
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: 1, padding: 10 }}>
                    {i.unit && i.unit.todaysPosition && (
                      <Box
                        sx={{ textAlign: 'center', marginTop: -20 }}
                      >
                        <FormText>AUTO-FILLED</FormText>
                      </Box>
                    )}
                    <CustomSelectInput
                      options={posOpts}
                      getOptionLabel={i => i.name}
                      getOptionValue={i => i.id}
                      defaultValue={defaultUnit}
                      value={defaultUnit}
                      onChange={selected =>
                        updateExisting(selected, 'unit', i.id || k)
                      }
                      name={unitKey}
                      label={`Unit ${k + 1}`}
                    />
                  </Box>

                  <Button
                    onClick={() => removeExisting(i.id || k)}
                    type="button"
                    sx={{ mt: '13px' }}
                    variant="linkDanger"
                  >
                    <Icon
                      icon="trash"
                      color="danger"
                      height={15}
                      width={15}
                    />
                  </Button>
                </Flex>
              </Box>
            )
          })}

      <>
        <Button
          variant="secondary"
          onClick={() => addResponderInstance()}
        >
          Add +
        </Button>
      </>
    </Box>
  )
}

const getDefaultResource = ({ users, positions, values }) => {
  const defaultUnit =
    typeof values.unit === 'string'
      ? optionalChaining(
          () => positions.filter(i => values.unit.id === i.id)[0]
        )
      : values.unit

  const defaultPersonnel =
    typeof values.personnel === 'string'
      ? optionalChaining(
          () => users.filter(i => values.responderId === i.id)[0]
        )
      : values.personnel

  return {
    defaultPersonnel: defaultPersonnel && {
      name: defaultPersonnel.name,
      id: defaultPersonnel.id
    },
    defaultUnit: defaultUnit && {
      name: defaultUnit.name,
      id: defaultUnit.id
    }
  }
}
