//Changes to theme need to changed in ./styles/_variables file as well for consistency
//Theme: https://coolors.co/28a745-e91d1d-0d1321-1d2d44-84acce

// eslint-disable-next-line no-unused-vars
import { darken } from '@theme-ui/color'

export const theme = {
  styles: {
    root: {
      body: {
        fontFamily: `'Arial', Monaco, Consolas, 'Liberation Mono',
        'Courier New', monospace`,
        margin: '0px',
        fontSize: '0.875rem',
        fontWeight: 'normal',
        overflowY: 'hidden',
        color: 'gray700',
        textAlign: 'left',
        backgroundColor: 'background'
      },
      h1: {
        variant: 'text.heading',
        fontSize: '2.5rem'
      },
      h2: {
        variant: 'text.heading',
        fontSize: '2rem'
      },
      h3: {
        variant: 'text.heading',
        fontSize: '1.75rem'
      },
      h4: {
        variant: 'text.heading',
        fontSize: '1.5rem'
      },
      h5: {
        variant: 'text.heading',
        fontSize: '1.25rem'
      },
      h6: {
        variant: 'text.heading',
        fontSize: '1rem'
      },
      p: {
        variant: 'text.body',
        marginBottom: '0.6rem'
      },

      table: {
        width: '100%',
        // border: '1px solid #e9ecef',
        marginBottom: '1rem',
        color: 'gray700',
        backgroundColor: 'background', // Reset for nesting within parents with `background-color`.
        borderCollapse: 'separate',
        borderSpacing: 0,
        overflowX: 'auto'
      },
      th: {
        variant: 'text.body',
        padding: '.75rem',
        verticalAlign: 'top',
        border: '1px solid ',
        borderColor: 'gray300',
        fontWeight: 'bold',
        background: 'background'
      },

      td: {
        variant: 'text.body',
        padding: '.75rem',
        verticalAlign: 'top',
        border: '1px solid ',
        borderColor: 'gray300',
        background: 'background'
      },
      tBody: {
        // border: '1px solid ',
        borderColor: 'gray300'
      },
      thead: {
        verticalAlign: 'bottom',
        borderBottom: '2px solid ',
        borderColor: 'gray300'
      },
      tfoot: {
        verticalAlign: 'bottom',
        borderTop: '2px solid ',
        borderColor: 'gray300'
      },
      a: {
        color: 'warning',
        cursor: 'pointer',
        '&:visited': {
          textDecoration: 'none'
        }
      },
      li: {
        mb: '3px'
      }
    }
  },

  text: {
    heading: {
      marginTop: '0px',
      marginBottom: '0.5rem',
      fontFamily: `'Arial', Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace`,
      lineHeight: 1.1,
      fontWeight: 500
    },
    body: {
      marginTop: '0px',
      marginBottom: '0.6rem',
      fontFamily: `'Open sans', sans-serif`,
      fontWeight: 400,
      fontSize: '0.875rem'
    }
  },
  colors: {
    background: '#fff',
    primary: '#1d2d44',
    accentBlue: '#06a9f5',
    primaryLight: '#06a9f540',
    primaryExtraLight: '#06a9f515',
    orangeLight: '#fd7e1440',
    orangeExtraLight: '#fd7e1415',
    warningLight: '#84acce40',
    warningExtraLight: '#84acce15',
    successLight: '#28a74540',
    successExtraLight: '#28a74515',
    dangerLight: '#e91d1d40',
    dangerExtraLight: '#e91d1d15',
    yellowLight: '#ffc10740',
    yellowExtraLight: '#ffc10715',
    secondary: '#8e959c',
    warning: '#84acce',
    danger: '#e91d1d',
    critical: '#999',
    success: '#28a745',
    orange: '#fd7e14',
    info: '#17a2b8',
    infoLight: '#17a2b840',
    white: '#fff',
    purpleLight: '#D5AEFF',
    purple: '#D5AEFF',
    teal: '#84acce',
    yellow: '#ffc107',
    gray100: '#f8f9fa',
    gray200: '#e9ecef',
    gray300: '#dee2e6',
    gray400: '#ced4da',
    gray500: '#adb5bd',
    gray600: '#8e959c',
    gray700: '#495057',
    gray800: '#343a40',
    gray900: '#212529',
    black: '#000',
    text: '#495057',
    contrastText: '#212529',
    modes: {
      dark: {
        background: '#212529',
        primary: '#395987',
        accentBlue: '#035a95',
        primaryLight: '#035a9528',
        primaryExtraLight: '#035a9510',
        orangeLight: '#a3410d28',
        orangeExtraLight: '#a3410d10',
        warning: '#8fa5b8',
        warningLight: '#8fa5b840',
        warningExtraLight: '#8fa5b815',
        successLight: '#1a5a2328',
        successExtraLight: '#1a5a2310',
        dangerLight: '#a10f0f28',
        dangerExtraLight: '#a10f0f10',
        yellowLight: '#a3850528',
        yellowExtraLight: '#a3850510',
        secondary: '#495057',
        danger: '#a10f0f',
        critical: '#555',
        success: '#1a5a23',
        orange: '#a3410d',
        info: '#09555a',
        white: '#fff',
        purpleLight: '#724b99',
        purple: '#724b99',
        teal: '#7a92a8',
        yellow: '#a38505',
        gray900: '#f8f9fa',
        gray800: '#e9ecef',
        gray700: '#dee2e6',
        gray600: '#ced4da',
        gray500: '#adb5bd',
        gray400: '#8e959c',
        gray300: '#495057',
        gray200: '#343a40',
        gray100: '#212529',
        black: '#000',
        text: '#d6d6d6', // bright text for dark background,
        contrastText: '#fff'
      }
    }
  },
  breakpoints: ['0px', '576px', '768px', '992px', '1200px'],
  space: [
    `${1}rem`,
    `${1 * 0.25}rem`,
    `${1 * 0.5}rem`,
    `${1 * 1}rem`,
    `${1 * 1.5}rem`,
    `${1 * 3}rem`
  ],
  borders: {
    default: '1px solid #e9ecef'
  },
  shadows: {
    card: '0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)'
  },
  page: {
    paddingBottom: '120px'
  },
  card: {
    borderRadius: '0.2rem',
    backgroundColor: 'background',
    padding: 3,
    border: '1px',
    borderStyle: 'solid',
    borderColor: 'gray300'
  },
  cards: {
    default: {
      backgroundColor: 'background',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      minWidth: '0px',
      wordWrap: 'break-word',
      backgroundClip: 'border-box',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray300',
      borderRadius: '0.2rem'
    }
  },
  container: {
    maxWidth: '1600px',
    margin: '0 auto',
    height: '100%',
    padding: '40px 20px',
    flex: 1,
    width: '100%',
    '@media(max-width: 768px)': {
      width: '100%',
      padding: '20px 10px 50px 10px',
      marginLeft: '0px'
    }
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 -15px'
  },
  column: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    flexBasis: 0,
    flexGrow: 1,
    maxWidth: '100%',
    padding: '0 15px'
  },
  label: {
    marginBottom: '0.5rem',
    width: 'auto',
    display: 'flex',
    alignItems: 'center'
  },
  ListGroup: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px',
    marginBottom: '0px'
  },
  ListGroupItem: {
    position: 'relative',
    display: 'block',
    padding: '0.75rem 1.25rem',
    bg: 'background',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray300',
    '&:first-of-type': {
      borderTopLeftRadius: '0.2rem',
      borderTopRightRadius: '0.2rem'
    },
    '&:last-of-type': {
      marginBottom: '0px',
      borderBottomLeftRadius: '0.2rem',
      borderBottomRightRadius: '0.2rem'
    }
  },
  ListGroupItemFlexRow: {
    position: 'relative',
    padding: '0.75rem 1.25rem',
    bg: 'background',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.125)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:first-of-type': {
      borderTopLeftRadius: '0.2rem',
      borderTopRightRadius: '0.2rem'
    },
    '&:last-of-type': {
      marginBottom: '0px',
      borderBottomLeftRadius: '0.2rem',
      borderBottomRightRadius: '0.2rem'
    }
  },
  Jumbotron: {
    padding: ['4rem 2rem', null, null, '2rem 1rem'],
    marginBottom: '2rem',
    backgroundColor: 'gray200',
    borderRadius: '0.3rem'
  },
  NavBar: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0.5rem 1rem'
  },
  NavbarBrand: {
    display: 'inline-block',
    marginRight: '1rem',
    // fontSize: '1.25rem',
    lineHeight: 'inherit',
    padding: '0px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  Nav: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '0px',
    marginBottom: '0px',
    listStyle: 'none',
    alignItems: 'center',
    gap: '10px'
  },
  NavItem: {
    padding: '0px',
    margin: '0px',
    listStyle: 'none'
  },
  ModalFooter: {
    display: 'flex',
    width: '100%',

    gap: '10px',
    justifyContent: 'flex-end',
    alignItems: 'center',

    pt: '1rem'
  },
  ModalBody: {
    position: 'relative',
    flex: '1 1 auto',
    paddingTop: '1rem'
  },
  ModalHeader: {
    display: 'flex',
    alignItems: 'flex-start', // so the close btn always stays on the upper right corner
    justifyContent: 'space-between', // Put modal header elements (title and dismiss) on opposite ends
    paddingb: '15px',
    borderTopRadius: '0.3rem'
  },
  forms: {
    input: {
      primary: {
        display: 'block',
        width: '100%',
        height: 'auto',
        padding: '0.5rem 0.75rem',
        fontFamily: 'inherit',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: 1.25,
        color: 'text',
        backgroundColor: 'background',
        backgroundClip: 'padding-box',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray300',
        borderRadius: '0.2rem',
        '&:focus': {
          borderColor: 'primary',
          boxShadow: theme =>
            `inset 0px 1px 1px ${theme.colors.gray200}`
        },
        '&:disabled': {
          backgroundColor: 'gray200',
          color: 'gray500',
          cursor: 'not-allowed'
        },
        '&::placeholder': {
          color: 'gray500',
          fontSize: 'small',
          opacity: 1
        }
      },
      tappableInput: {
        minHeight: '70px',
        p: '.2em',
        position: 'relative',
        backgroundColor: 'secondary',
        whiteSpace: 'normal',
        overflowWrap: 'normal',
        fontFamily: 'inherit',
        border: 'none',
        color: 'white',
        outline: 'none',
        boxShadow: 'none',
        cursor: 'pointer',
        '&:active': {
          filter: 'brightness(0.75)'
        },
        '&:focus': {
          filter: 'brightness(0.75)'
        },
        '&:hover': {
          filter: 'brightness(0.75)'
        }
      }
    },
    textarea: {
      variant: 'forms.input'
    }
  },
  alerts: {
    warning: {
      gap: '20px',
      color: 'text',
      fontWeight: 'normal',
      fontSize: '14px',
      bg: 'yellowExtraLight',
      borderWidth: '1px',
      borderColor: 'yellowLight',
      borderStyle: 'solid',
      padding: '15px 20px 15px 16px',
      borderRadius: '5px',
      flexWrap: 'wrap',
      my: '10px',
      justifyContent: 'space-between'
    },
    danger: {
      gap: '20px',
      color: 'text',
      fontWeight: 'normal',
      fontSize: '14px',
      bg: 'dangerExtraLight',
      borderWidth: '1px',
      borderColor: 'dangerLight',
      flexWrap: 'wrap',
      borderStyle: 'solid',
      padding: '15px 20px 15px 16px',
      borderRadius: '5px',
      my: '10px',
      justifyContent: 'space-between'
    },
    primary: {
      gap: '20px',
      color: 'text',
      fontWeight: 'normal',
      fontSize: '14px',
      bg: 'primaryExtraLight',
      borderWidth: '1px',
      borderColor: 'primaryLight',
      flexWrap: 'wrap',
      borderStyle: 'solid',
      padding: '15px 20px 15px 16px',
      borderRadius: '5px',
      my: '10px',
      justifyContent: 'space-between'
    },
    success: {
      gap: '20px',
      color: 'text',
      fontWeight: 'normal',
      fontSize: '14px',
      bg: 'successExtraLight',
      borderWidth: '1px',
      borderColor: 'successLight',
      borderStyle: 'solid',
      padding: '15px 20px 15px 16px',
      flexWrap: 'wrap',
      borderRadius: '5px',
      my: '10px',
      justifyContent: 'space-between'
    }
  },
  buttons: {
    primary: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'none',
      gap: '10px',
      cursor: 'pointer',
      textTransform: 'capitalize',
      color: 'white',
      bg: 'primary',
      fontFamily: 'inherit',
      fontSize: '.775rem',
      boxSizing: 'border-box',
      transition: 'all 0.15s ease-in-out',
      padding: '0.5rem 0.75rem',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'primary',
      borderRadius: '0.2rem',
      lineHeight: 1.25,

      verticalAlign: 'middle',
      textAlign: 'center',

      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(63, 77, 96, 0.3)'
      },
      '&:hover': {
        backgroundColor: darken('primary', 0.05)
      },

      '&:disabled': {
        opacity: 0.4,
        boxShadow: 'none',
        cursor: 'not-allowed'
      }
    },
    secondary: {
      variant: 'buttons.primary',
      bg: 'secondary',
      borderColor: 'secondary',
      '&:hover': {
        backgroundColor: darken('secondary', 0.05)
      }
    },
    danger: {
      variant: 'buttons.primary',
      borderColor: 'danger',
      bg: 'danger',
      '&:hover': {
        backgroundColor: darken('danger', 0.1)
      }
    },
    warning: {
      variant: 'buttons.primary',
      borderColor: 'warning',
      bg: 'warning',
      '&:hover': {
        backgroundColor: darken('warning', 0.1)
      }
    },
    info: {
      variant: 'buttons.primary',
      borderColor: 'info',
      bg: 'info',
      '&:hover': {
        backgroundColor: darken('info', 0.1)
      }
    },
    success: {
      variant: 'buttons.primary',
      borderColor: 'success',
      bg: 'success',
      '&:hover': {
        backgroundColor: darken('success', 0.1)
      }
    },
    orange: {
      variant: 'buttons.primary',
      bg: 'orange',
      borderColor: 'orange',
      '&:hover': {
        backgroundColor: darken('orange', 0.05)
      }
    },
    white: {
      variant: 'buttons.primary',
      color: 'text',
      borderColor: 'white',
      bg: 'background',
      borderColor: 'gray200',
      '&:hover': {
        color: 'text',
        backgroundColor: 'gray200'
      }
    },

    link: {
      variant: 'buttons.primary',
      borderColor: 'transparent',
      background: 'none',
      color: 'warning',
      boxShadow: 'none',
      fontSize: '.875rem',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
        color: darken('warning', 0.1)
      },
      '&:disabled': {
        color: 'gray600',
        cursor: 'not-allowed'
      }
    },
    linkGray: {
      variant: 'buttons.primary',
      borderColor: 'transparent',
      background: 'none',
      textDecoration: 'none',
      color: 'gray700',
      boxShadow: 'none',
      fontSize: '.875rem',
      '&:hover': {
        cursor: 'pointer',
        color: darken('gray700', 0.1)
      },
      '&:disabled': {
        color: 'gray600'
      }
    },
    linkDanger: {
      variant: 'buttons.primary',
      borderColor: 'transparent',
      background: 'none',
      textDecoration: 'none',
      color: 'danger',
      boxShadow: 'none',
      fontSize: '.875rem',
      '&:hover': {
        cursor: 'pointer',
        color: darken('danger', 0.1)
      },
      '&:disabled': {
        color: 'gray600',
        cursor: 'not-allowed'
      }
    },
    linkSuccess: {
      variant: 'buttons.primary',
      borderColor: 'transparent',
      background: 'none',
      textDecoration: 'none',
      color: 'success',
      boxShadow: 'none',
      fontSize: '.875rem',
      '&:hover': {
        cursor: 'pointer',
        color: darken('success', 0.1)
      },
      '&:disabled': {
        color: 'gray600',
        cursor: 'not-allowed',
        cursor: 'not-allowed'
      }
    },
    naked: {
      variant: 'buttons.primary',
      backgroundColor: 'transparent',
      border: 'transparent',
      boxShadow: 'none',
      color: 'text',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    dropdown: {
      variant: 'buttons.naked',
      color: 'text',
      width: '100%',
      minWidth: '100%',
      bg: 'background',
      textAlign: 'left',
      justifyContent: 'flex-start',
      displat: 'block'
    },
    iconBox: {
      variant: 'buttons.secondary',
      bg: 'gray200',
      color: 'gray700',
      height: '180px',
      width: '180px',
      display: 'flex',
      border: 'none',
      flexDirection: 'column',
      gap: '20px',
      alignItems: 'center',
      fontWeight: '600',
      '&:hover': {
        cursor: 'pointer',
        color: darken('gray700', 0.1)
      }
    }
  },

  badges: {
    primary: {
      bg: 'primary',
      color: 'white',
      display: 'inline-block',
      padding: '.6em',
      fontSize: '11px',
      border: '1px solid',
      borderColor: 'transparent',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '4px',
      // lineHeight: 1,
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'baseline',
      borderRadius: '.3rem',
      wrap: 'nowrap'
    },
    secondary: {
      variant: 'badges.primary',
      borderColor: 'secondary',
      bg: 'secondary'
    },
    danger: {
      variant: 'badges.primary',
      borderColor: 'danger',
      bg: 'danger'
    },
    info: {
      variant: 'badges.primary',
      borderColor: 'info',
      bg: 'info'
    },
    warning: {
      variant: 'badges.primary',
      borderColor: 'warning',
      bg: 'warning'
    },
    success: {
      variant: 'badges.primary',
      borderColor: 'success',
      bg: 'success'
    },
    orange: {
      variant: 'badges.primary',
      borderColor: 'orange',
      bg: 'orange'
    },
    primaryLight: {
      variant: 'badges.primary',
      bg: 'primaryLight',
      color: 'primary',
      borderColor: 'none'
    },
    primaryReverse: {
      variant: 'badges.primary',
      color: 'danger',
      padding: '0px',
      border: 'none',
      bg: 'background'
    },
    dangerReverse: {
      variant: 'badges.primary',
      color: 'danger',
      padding: '0px',
      border: 'none',
      bg: 'background'
    },
    secondaryReverse: {
      variant: 'badges.primary',
      color: 'secondary',
      padding: '0px',
      border: 'none',
      bg: 'background'
    },
    warningReverse: {
      variant: 'badges.primary',
      color: 'warning',
      padding: '0px',
      border: 'none',
      bg: 'background'
    },
    successReverse: {
      variant: 'badges.primary',
      color: 'success',
      padding: '0px',
      border: 'none',
      bg: 'background'
    },
    orangeReverse: {
      variant: 'badges.primary',
      color: 'orange',
      padding: '0px',
      border: 'none',
      bg: 'background'
    }
  }
}
