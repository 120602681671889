/** @jsxImportSource theme-ui */

import { Fragment, useEffect, useRef } from 'react'

import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'

import styled from '@emotion/styled'
import Quill from 'quill'

const Link = Quill.import('formats/link')

Link.sanitize = function(url) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `http://${url}`
  }
  return url
}

const QuillStyles = styled.div`
  width: 100%;

  .ql-picker-label {
    color: ${props => props.theme.colors.text} !important;
  }

  .ql-editor.ql-blank::before {
    color: ${props => props.theme.colors.text} !important;
  }
  .ql-container {
    height: 90%;
  }
  .ql-editor {
    min-height: ${p => p.minHeight || '100%'};
    -webkit-user-select: text;
  }
  .ql-container.ql-snow {
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.gray300};
    border-radius: 0.2rem;
  }
`

const RichTextInput = ({
  onChange,
  hideToolbar,
  style,
  minHeight,
  value,
  disabled,
  autoFocus,
  onBlur,
  ...props
}) => {
  const ref = useRef(null)
  const defaultValue = props.defaultValue || value || ''

  useEffect(() => {
    if (autoFocus && ref?.current) {
      ref.current.focus()
    }

    const removeTabBinding = () => {
      if (ref.current === null) {
        return
      }
      const keyboard = ref.current.getEditor().getModule('keyboard')
      // 'hotkeys' have been renamed to 'bindings'
      delete keyboard.bindings[9]
    }

    removeTabBinding()
  }, [ref])

  if (typeof defaultValue !== 'string') {
    return <Fragment />
  }

  return (
    <QuillStyles
      minHeight={minHeight}
      style={{
        minHeight,
        position: 'relative',
        ...style
      }}
    >
      <ReactQuill
        ref={ref}
        theme="snow"
        onChange={content => {
          //handle empty text in case of required field
          onChange(content === '<p><br></p>' ? undefined : content)
        }}
        modules={{
          toolbar: hideToolbar && false,
          clipboard: {
            matchVisual: false
          }
        }}
        sx={{
          color: 'text',
          height: '100%',
          width: '100%'
        }}
        {...props}
        onBlur={(e, source) => {
          if (source === 'silent') {
            return
          }
          onBlur && onBlur(e)
        }}
        defaultValue={defaultValue}
        readOnly={disabled}
        value={value}
      />
    </QuillStyles>
  )
}

export default RichTextInput
