import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import config from '../../../src/config'
import Pusher from 'pusher-js'
import { usePusherContext } from '../hooks/usePusher'

// if (config.stage !== 'production') {
//   Pusher.logToConsole = true
// }

// context setup
const ChannelsContext = createContext({})
export const __ChannelsContext = ChannelsContext

/**
 * Provider that creates your channels instances and provides it to child hooks throughout your app.
 */
export const ChannelsProvider = ({ children }) => {
  const { client } = usePusherContext()
  const connectedChannels = useRef({})

  const subscribe = useCallback(
    channelName => {
      /** Return early if there's no client */
      if (!client || !channelName) return

      /** Subscribe to channel and set it in state */
      const pusherChannel = client.subscribe(channelName)
      connectedChannels.current[channelName] = [
        ...(connectedChannels.current[channelName] || []),
        pusherChannel
      ]
      return pusherChannel
    },
    [client, connectedChannels]
  )

  const unsubscribe = useCallback(
    channelName => {
      /** Return early if there's no props */
      if (
        !client ||
        !channelName ||
        !(channelName in connectedChannels.current)
      )
        return

      /** If just one connection, unsubscribe totally */
      if (connectedChannels.current[channelName].length === 1) {
        client.unsubscribe(channelName)
        delete connectedChannels.current[channelName]
      } else {
        connectedChannels.current[channelName].pop()
      }
    },
    [connectedChannels, client]
  )

  const getChannel = useCallback(
    channelName => {
      /** Return early if there's no client */
      if (
        !client ||
        !channelName ||
        !(channelName in connectedChannels.current)
      )
        return

      /** Return channel */
      return connectedChannels.current[channelName][0]
    },
    [connectedChannels, client]
  )

  return (
    <ChannelsContext.Provider
      value={{
        unsubscribe,
        subscribe,
        getChannel
      }}
    >
      {children}
    </ChannelsContext.Provider>
  )
}

// context setup
const PusherContext = createContext({})
export const __PusherContext = PusherContext

/**
 * Provider that creates your pusher instance and provides it to child hooks throughout your app.
 * Note, you can pass in value={{}} as a prop if you'd like to override the pusher client passed.
 * This is handy when simulating pusher locally, or for testing.
 * @param props Config for Pusher client
 */

export const CorePusherProvider = ({
  clientKey,
  cluster,
  children,
  ...props
}) => {
  // errors when required props are not passed.
  useEffect(() => {
    if (!clientKey)
      console.error('A client key is required for pusher')
    if (!cluster) console.error('A cluster is required for pusher')
  }, [clientKey, cluster])

  const config = useMemo(() => ({ cluster, ...props }), [
    cluster,
    props
  ])

  // track config for comparison
  const previousConfig = useRef(props)
  useEffect(() => {
    previousConfig.current = props
  })
  const [client, setClient] = useState()

  useEffect(() => {
    // Skip creation of client if deferring, a value prop is passed, or config props are the same.
    if (client) {
      return
    }
    setClient(new Pusher(clientKey, config))
  }, [clientKey, props, config])

  return (
    <PusherContext.Provider
      value={{
        client
      }}
      {...props}
    >
      <ChannelsProvider>{children}</ChannelsProvider>
    </PusherContext.Provider>
  )
}

const pconfig = {
  // required config props
  clientKey: config.PUSHER_KEY,
  cluster: config.PUSHER_CLUSTER
}

// Wrap app in provider
const PusherProvider = ({ children }) => {
  return (
    <CorePusherProvider {...pconfig}>{children}</CorePusherProvider>
  )
}

export default PusherProvider
