import { createContext, Fragment, useEffect } from 'react'
import { checkRoleMatch, useLogout } from '../services/auth'

import Unauthorized from '../containers/Unauthorized'
import { useGetCurrentAgencyContext } from '../hooks/queries'
import { useStytchMemberSession } from '@stytch/react/b2b'
import { useNetworkStatus } from '../hooks/useNetworkStatus'
import { useLocation, useNavigate } from 'react-router'

export const CurrentAgencyContext = createContext({
  loading: true
})

export const PrivateRoute = props => {
  const networkStatus = useNetworkStatus()
  const { session } = useStytchMemberSession()
  const navigate = useNavigate()
  const {
    loading,
    user,
    currentAgency
  } = useGetCurrentAgencyContext()

  const { logout } = useLogout()
  const location = useLocation()
  const { component: Component, notAllowed, allowed, ...rest } = props

  useEffect(() => {
    async function handleNotAuthed() {
      // If the user is not logged in, redirect to the login page.
      //Or deep link to proper path.  Default is agency/submission

      const isAgencyDeep = location.pathname === '/agency'
      const isForcedDeep =
        location.search && location.search.includes('deep')

      await logout(
        isAgencyDeep && !isForcedDeep
          ? false
          : {
              state: {
                deepLinkTo:
                  location.pathname + (location.search || '')
              }
            }
      )
    }

    // Allow offline mode..
    if (
      !session &&
      location.pathname !== `/agency/login` &&
      networkStatus
    ) {
      handleNotAuthed()
    }

    if (session && !loading && (!user || !currentAgency)) {
      navigate('/agency/onboarding')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, location, currentAgency])

  if (!session) {
    return <Fragment />
  }

  if (!checkRoleMatch(location.pathname, user?.role)) {
    return <Unauthorized {...rest} />
  }
  return <Component search={location.search} {...rest} />
}

export default PrivateRoute
