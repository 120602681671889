const c = {
  development: {
    subdomain: '',
    domain: 'localhost:3001',
    stageDomain: 'localhost',
    ssoUrl: 'https://test.stytch.com/v1/public/sso/start'
  },
  staging: {
    subdomain: 'staging.app',
    domain: 'https://staging.app.yourwatchtower.com',
    stageDomain: '.staging.app.yourwatchtower.com',
    ssoUrl: 'https://test.stytch.com/v1/public/sso/start'
  },
  production: {
    subdomain: 'app',
    domain: 'https://app.yourwatchtower.com',
    stageDomain: '.yourwatchtower.com',
    ssoUrl: 'https://api.stytch.com/v1/public/sso/start'
  },

  WS_URL: import.meta.env.VITE_WS_URL,
  GRAPHQL_URL: import.meta.env.VITE_GRAPHQL_URL,
  GRAPHQL_ORIGIN_URL: import.meta.env.VITE_GRAPHQL_URL_ORIGIN,
  MAPBOX_TOKEN: import.meta.env.VITE_MAPBOX_TOKEN,
  POST_HOG_KEY: import.meta.env.VITE_POST_HOG_KEY,
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  stage: import.meta.env.VITE_STAGE,
  supportNumber: '(714) 316-9734',
  bugSnagKey: import.meta.env.VITE_BUG_SNAG_KEY || '',
  frontendVersion: parseFloat(
    import.meta.env.VITE_FRONTEND_VERSION || 5.8
  ),
  CUBE_JS_API_URL: import.meta.env.VITE_CUBE_JS_API_URL,
  BETTER_UPTIME_KEY: import.meta.env.VITE_BETTER_UPTIME_KEY,
  MICROSOFT_LOGIN_URL: import.meta.env.VITE_MICROSOFT_LOGIN_URL,
  GOOGLE_LOGIN_URL: import.meta.env.VITE_GOOGLE_LOGIN_URL,
  PUSHER_KEY: import.meta.env.VITE_PUSHER_KEY,
  STYTCH_PUBLIC_KEY: import.meta.env.VITE_STYTCH_PUBLIC_TOKEN,
  PUSHER_CLUSTER: import.meta.env.VITE_PUSHER_CLUSTER
}
const config = {
  ...c,
  ...c[import.meta.env.VITE_STAGE]
}

export default config
