/** @jsxImportSource theme-ui */

import { Fragment, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { NetworkStatus, useQuery } from '@apollo/client'
import { cleanGraphQLError } from '../components/Forms/FormError'
import { Box, Flex } from 'theme-ui'
import {
  GET_HOMEBASE_INFO,
  GET_HOMEBASE_TASKS,
  useGetCurrentAgencyContext
} from '../hooks/queries'
import get from 'lodash/get'
import { CenteredContent } from '../components'
import DispatchStatusColor, {
  DispatchColorKey
} from '../components/Dispatch/DispatchStatusColor'
import { prettyDate } from 'src/utils'
import { RolesOnly } from '../services/auth'
import { CreateBulletinModalButton } from '../components/Forms/CreateBulletin'

import WeatherWidget from '../components/HomeBase/WeatherWidget'
import PayWallScreen, {
  getTruthyPermissionsFromSettings
} from '../components/PayWalls/PayWallScreen'

import ImportantSubmissionList from '../components/Reporting/ImportantSubmissionList'
import moment from 'moment'
import DocumentList from '../components/Operations/Documents/DocumentList'
import FormList from '../components/Operations/Forms/FormList'
import { useNetworkStatus } from '../hooks/useNetworkStatus'
import Card from '../components/Shared/Elements/Card'
import ListGroup from '../components/Shared/Elements/ListGroup'
import ListGroupItem from '../components/Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../components/Shared/Elements/ListGroupItemHeading'

import { DISPATCH_SUB } from '../hooks/subscriptions'

import NWSAlets from '../components/HomeBase/NwsAlerts'
import Spinner from 'src/images/icons/Spinner'
import OfflineDisabledInlineTip from '../components/Shared/OfflineDisabledInlineTip'
import { useNavigate } from 'react-router'
import SubscriptionButton from '../components/Shared/SubscriptionButton'
import CustomTabs from '../components/Shared/CustomTabs'

import { Overline } from '../components/Shared'

import ModalButton from '../components/Shared/ModalButton'
import DailyAssignmentLabel from '../components/DailyAssignmentLabel'
import DailyAssignmentForm from '../components/Forms/DailyAssignmentForm'
import BulletinList from '../components/HomeBase/Bulletin'
import Icon from '../components/Shared/Icon'

import { FormSubmitRendererModalButton } from '../components/Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'

import BulkIncident from '../components/QuickSurvey/BulkIncident'
import Badge from '../components/Shared/Elements/Badge'

import BulkSubmissionEditContent from '../components/Stats/BulkSubmissionEditContent'
import Button from '../components/Shared/Elements/Button'

import CustomSelectInput from '../components/Forms/CustomSelectInput'
import QuickSurvey from './QuickSurveyBranch'
import ReactConfetti from 'react-confetti'
import { presetColors } from '../components/Shared/HexColorPicker'
import EditSubmissionsList from '../components/QuickSurvey/EditSubmissionsList'

import LinkResponseToStepModalButton from '../components/HomeBase/Workflows/LinkResponseToStepModalButton'
import { StopPropogation } from '../components/Shared/StopPropogation'
import QuestionTooltip from '../components/Shared/QuestionTooltip'
import useGetTodaysAssignment from '../hooks/useGetTodaysAssignment'
import groupBy from 'lodash/groupBy'

import { RichTextRender } from '../components/Shared/RichTextRender'
import Switch from '../components/Shared/Elements/Switch'
import {
  SupervisorReviewPin,
  WorkflowPins
} from './Agency/WorkflowPins'

const StyledListGroupItem = styled(ListGroupItem)`
  ${p => `border-left: 3px solid ${p.theme.colors[p.accent]};`},
`

const HomeBase = () => {
  const isOnline = useNetworkStatus()
  const [showOldEx, setShowOldEx] = useState(false)
  const {
    error: agencyError,
    currentAgency,
    user,
    loading
  } = useGetCurrentAgencyContext()

  const e = agencyError && cleanGraphQLError(agencyError.message)

  if (e && isOnline)
    return (
      <CenteredContent>
        Oops, there was an error. Try reloading your browser.
      </CenteredContent>
    )

  if (!currentAgency) return <Spinner centered />

  const hasWorkflows = getTruthyPermissionsFromSettings(
    currentAgency?.settings
  )?.includes('workflows')

  return (
    <Box>
      <OfflineDisabledInlineTip />
      {!loading && <NWSAlets />}
      {hasWorkflows && (
        <Flex
          sx={{
            justifyContent: 'flex-end',
            width: '100%',
            my: '20px'
          }}
        >
          <Switch
            style={{ cursor: 'pointer' }}
            id={'showOldEx'}
            name="showOldEx"
            checked={showOldEx}
            readOnly
            label="Show Old Home"
            onClick={() => {
              setShowOldEx(!showOldEx)
            }}
          />
        </Flex>
      )}
      {hasWorkflows && !showOldEx ? (
        <Flex
          sx={{
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%',
            alignItems: 'stretch'
          }}
        >
          <CustomTabs
            navItemComponent={() => (
              <Flex sx={{ alignItems: 'center', gap: '5px' }}>
                <ModalButton
                  modalHeader={'Submit a Form'}
                  buttonLabel={'Form Library'}
                  buttonProps={{
                    variant: 'secondary'
                  }}
                >
                  {({ toggle }) => (
                    <FormList
                      hideFilters={['showSupReview', 'showArchived']}
                      submitToastText="Form Submitted"
                      hideCreateButton
                      hideEdit
                      hideResponseModal
                      onFormSubmitFinish={toggle}
                      pinnedFirst
                    />
                  )}
                </ModalButton>
                <ModalButton
                  buttonLabel={'Submit an Incident'}
                  contentSize="lg"
                  contentHeight={'90vh'}
                  bodyStyles={{
                    overflow: 'auto'
                  }}
                  modalProps={{
                    overflow: 'auto',
                    shouldCloseOnEsc: false,
                    checkOnClose: true,
                    stickySubmit: true
                  }}
                  modalHeader={'Submit incident'}
                  buttonProps={{
                    variant: 'primary'
                  }}
                >
                  {({ toggle }) => {
                    return (
                      <QuickSurvey
                        onFinish={toggle}
                        useTodaysPosition
                        typeOnly={false}
                        stickySubmit
                        extendedQuickSurvey
                        includeResponseInputs
                      />
                    )
                  }}
                </ModalButton>
              </Flex>
            )}
            tabs={[
              {
                linkTitle: "Today's Work",
                component: (
                  <Box
                    sx={{
                      py: '30px'
                    }}
                  >
                    <WorkflowPins />
                    <Workflow
                      currentAgency={currentAgency}
                      user={user}
                    />
                  </Box>
                )
              },
              {
                linkTitle: "What's Happening",
                component: (
                  <WhatsHappening currentAgency={currentAgency} />
                )
              },
              {
                linkTitle: 'My Past Work',
                component: <MyActivityLog />
              }
            ]}
          />
        </Flex>
      ) : (
        <OriginalView currentAgency={currentAgency} />
      )}
    </Box>
  )
}

const MyActivityLog = () => {
  return (
    <Box
      sx={{
        py: 40
      }}
    >
      <EditSubmissionsList />
    </Box>
  )
}

const OriginalView = ({ currentAgency }) => {
  return (
    <>
      <Box
        sx={{
          mb: 30
        }}
      >
        <SupervisorReviewPin />
      </Box>
      <Flex
        sx={{
          flexDirection: 'row',
          justifyContent: 'stretch',
          gap: '20px',
          flexWrap: 'wrap'
        }}
      >
        <PayWallScreen featureLabel="comms" hideContent>
          <HomeBaseLeftPanel />
        </PayWallScreen>
        <HomeBaseRightPanel currentAgency={currentAgency} />
      </Flex>
    </>
  )
}

const HomeBaseLeftPanel = () => {
  return (
    <Box
      sx={{
        margin: '0 auto 0 0',
        mb: ['50px', null, null, null, null, '0px'],
        pb: 20,
        flex: 1,
        minWidth: '300px'
      }}
    >
      <Flex
        sx={{
          width: '100%',
          py: '10px',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h5>Bulletins</h5>
        <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
          <CreateBulletinModalButton />
        </RolesOnly>
      </Flex>

      <BulletinList />
    </Box>
  )
}

const HomeBaseRightPanel = ({ currentAgency }) => {
  const navigate = useNavigate()
  const startDate = moment(new Date())
    .startOf('D')
    .toISOString()
  const endDate = moment(new Date())
    .endOf('D')
    .toISOString()

  const { data, loading, refetch } = useQuery(GET_HOMEBASE_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        incidentTime: {
          gt: startDate,
          lt: endDate
        },
        important: {
          equals: true
        }
      }
    }
  })

  const importantIncidents = get(data, 'myAgenciesSubmissions', [])

  const activeDispatches = get(
    data || [],
    'myAgenciesSubmissionsWithActiveDispatches',
    []
  )

  const docList = useMemo(
    () => (
      <DocumentList
        pinnedOnly
        hideSearch
        hideLoadMore
        hideCreateButton
        hidePin
        hideEdit
        defaultSort="az"
      />
    ),
    []
  )

  const formList = useMemo(
    () => (
      <FormList
        pinnedOnly
        hideSearch
        hideLoadMore
        hideEdit
        hidePin
        hid
        hideCreateButton
        defaultSort="az"
      />
    ),
    []
  )

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        flexDirection: 'column',
        margin: '0 0 0 auto',
        flex: 1,
        minWidth: '300px',
        mb: ['50px', null, null, null, null, '0px']
      }}
    >
      <Box sx={{ mb: 40, width: '100%' }}>
        <PayWallScreen featureLabel="forms" hideContent>
          <Box
            sx={{ py: '10px', height: '50px', alignItems: 'center' }}
          >
            <h5>Documents</h5>
          </Box>
          {docList}
        </PayWallScreen>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <PayWallScreen featureLabel="forms" hideContent>
          <Box
            sx={{ py: '10px', height: '50px', alignItems: 'center' }}
          >
            <h5 sx={{ pb: 10 }}>Forms</h5>
          </Box>
          {formList}
        </PayWallScreen>
      </Box>
      <PayWallScreen hideContent featureLabel="dispatching">
        <Box sx={{ mb: 40, width: '100%' }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              py: '10px',
              height: '50px',
              alignItems: 'center'
            }}
          >
            <h5
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              Active Incidents{' '}
              <SubscriptionButton
                startOn
                minimal
                variables={{
                  agencyId: currentAgency.id
                }}
                query={DISPATCH_SUB}
                onSubscriptionData={() => {
                  // allow for cache to update...
                  setTimeout(() => refetch(), 300)
                }}
                externalLoading={loading}
              />
            </h5>
            <DispatchColorKey />
          </Flex>

          <Card>
            <ListGroup sx={{ padding: '1rem' }}>
              {activeDispatches && activeDispatches.length > 0 ? (
                activeDispatches.map(p => {
                  return (
                    <StyledListGroupItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/agency/dispatches')}
                      key={p.id}
                    >
                      <Flex
                        sx={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <ListGroupItemHeading>
                          {prettyDate(p.incidentTime, 'HH:mm')} -{' '}
                          {p.type && p.type.label}{' '}
                        </ListGroupItemHeading>

                        <ListGroupItemHeading>
                          {p.location &&
                            p.location.position &&
                            `${p.location.position.name}`}
                        </ListGroupItemHeading>
                      </Flex>
                      <Flex>
                        {p.dispatches.map(d => (
                          <DispatchStatusColor
                            style={{ marginRight: '3px' }}
                            oScene={d.onScene}
                            completedAt={d.completedAt}
                            enRoute={d.enRoute}
                          >
                            {d.unit ? d.unit.name : 'Unassigned'}
                          </DispatchStatusColor>
                        ))}
                      </Flex>
                    </StyledListGroupItem>
                  )
                })
              ) : (
                <Box>
                  <CenteredContent height={'200px'}>
                    {loading && <Spinner />}
                    No active calls{' '}
                  </CenteredContent>
                </Box>
              )}
            </ListGroup>
          </Card>
        </Box>
      </PayWallScreen>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Today's Notable Incidents</h5>
        <Box variant="card">
          <ImportantSubmissionList
            data={importantIncidents}
            loading={loading}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Weather</h5>
        <Box variant="card">
          <WeatherWidget
            {...currentAgency.settings}
            timezone={currentAgency.timezone}
          />
        </Box>
      </Box>
    </Box>
  )
}

const Workflow = ({ user }) => {
  const [showedWorkflow, setShowedWorkflow] = useState(0)
  const ts = useGetTodaysAssignment()

  const todaysResponders = ts.data?.todaysResponders
  const variables = {
    assignments: todaysResponders?.map(r => ({
      responderId: r.responderId,
      positionId: r.positionId
    }))
  }
  const {
    data,
    refetch,
    loading: loadingTasks,
    networkStatus,
    error
  } = useQuery(GET_HOMEBASE_TASKS, {
    skip: !todaysResponders,
    notifyOnNetworkStatusChange: true,
    variables
  })

  if (error && !data) {
    return (
      <CenteredContent>
        Oops, there was an error. Try reloading your browser.
      </CenteredContent>
    )
  }

  const initialLoading =
    networkStatus !== NetworkStatus.refetch && loadingTasks

  const workflowData = data?.myWorkflow?.workflows || {}

  const s = workflowData?.[showedWorkflow]?.steps || []

  const groupStepsByTimstamp =
    Object.entries(groupBy(s, 'timestamp')).map(([key, value]) => ({
      steps: value,
      timestamp: key
    })) || []

  return (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: 20,
        position: 'relative'
      }}
    >
      <h5
        sx={{
          my: '40px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '5px'
        }}
      >
        Hello{' '}
        <ModalButton
          buttonProps={{
            variant: 'secondary',
            outline: true,
            icon: 'right arrow',
            iconPosition: 'right',
            sx: {
              textTransform: 'none',
              ml: '5px',
              borderColor: 'gray400'
            }
          }}
          modalHeader={'Set Assignment'}
          buttonLabel={
            todaysResponders?.length < 1 ? (
              `${user.name} (Click to set assignment)`
            ) : (
              <>
                {' '}
                <DailyAssignmentLabel emptyText="Click to set assignment" />
              </>
            )
          }
        >
          {({ toggle }) => (
            <DailyAssignmentForm
              onFinish={() => {
                toggle()
                setShowedWorkflow(0)
              }}
            />
          )}
        </ModalButton>{' '}
        , today is {moment().format('dddd, MMMM Do YYYY')}.
      </h5>
      {initialLoading ? (
        <Spinner centered />
      ) : !todaysResponders ? (
        <CenteredContent height={'300px'}>
          Please set your assignments to continue.
        </CenteredContent>
      ) : (
        <Flex
          sx={{
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'space-between',

            position: 'relative',
            // ml: '30px',
            maxWidth: '800px'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '0px',
              left: '35px',
              bottom: '0px',
              bg: 'gray300',
              width: '1px',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1
            }}
          />

          {todaysResponders?.length > 1 ? (
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
                py: 20
              }}
            >
              <CustomSelectInput
                sx={{
                  minWidth: '200px',
                  zIndex: 1000
                }}
                id="workflow"
                name="workflow"
                value={
                  todaysResponders[showedWorkflow] && {
                    value: showedWorkflow,
                    label: `${todaysResponders[showedWorkflow]
                      .responderName || ''} - ${todaysResponders[
                      showedWorkflow
                    ].positionName || ''}`
                  }
                }
                onChange={e => {
                  setShowedWorkflow(e.value)
                }}
                options={todaysResponders?.map((r, k) => ({
                  label: `${r.responderName} - ${r.positionName}`,
                  value: k
                }))}
              />
            </Flex>
          ) : (
            <Fragment />
          )}

          {!s?.length ? (
            <Flex
              sx={{
                bg: 'background',
                mt: '30px',
                p: '20px',
                flexDirection: 'column',
                alignItems: 'space-between',
                zIndex: 2
              }}
            >
              <Flex
                sx={{
                  mb: '10px',
                  gap: '5px',
                  alignItems: 'center'
                }}
              >
                No tasks found
              </Flex>
            </Flex>
          ) : (
            groupStepsByTimstamp?.map(({ timestamp, steps }, k) => {
              return (
                <Card
                  key={k}
                  sx={{
                    position: 'relative',
                    bg: 'background',
                    mt: '30px',
                    p: '20px',
                    flexDirection: 'column',
                    alignItems: 'space-between',
                    zIndex: 500,
                    gap: '25px'
                  }}
                >
                  <Box
                    sx={{
                      fontSize: 2
                    }}
                  >
                    <strong>{timestamp}</strong>
                  </Box>
                  {steps.map((task, k) => {
                    const bulkSubmissions = task.responses?.filter(
                      r => r?.__typename === 'BulkSubmission'
                    )
                    const notDraftBulkOrUnlinkedToStep = bulkSubmissions?.filter(
                      fr => !fr.draft && fr.stepId
                    )

                    return (
                      <Box>
                        <Box
                          key={k}
                          sx={{
                            position: 'relative',
                            bg: 'background',

                            px: '20px',
                            flexDirection: 'column',
                            alignItems: 'space-between',
                            zIndex: 500
                          }}
                        >
                          <Flex
                            sx={{
                              mb: '30px',
                              gap: '5px',
                              width: '100%',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Flex
                              sx={{
                                gap: '5px',
                                alignItems: 'center'
                              }}
                            >
                              <strong> {task.title} </strong>
                              <QuestionTooltip
                                iconHeight={12}
                                iconWidth={12}
                                customIndex={task?.id + task.type}
                                tip={
                                  <Flex
                                    sx={{
                                      gap: '10px',
                                      alignItems: 'flex-start',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start'
                                    }}
                                  >
                                    <Overline>
                                      {' '}
                                      {task.workflowName}
                                    </Overline>
                                    <Flex
                                      sx={{
                                        alignItems: 'center'
                                      }}
                                    >
                                      <Overline
                                        sx={{
                                          fontSize: '10px',
                                          mb: '0px'
                                        }}
                                      >
                                        {task.type} in:
                                      </Overline>
                                      <Flex
                                        sx={{
                                          gap: '5px',
                                          ml: '10px',
                                          alignItems: 'center'
                                        }}
                                      >
                                        {task.displayTags?.map(t => (
                                          <Badge
                                            bg={t.color}
                                            size="sm"
                                            outline
                                            key={t.id}
                                          >
                                            {t.name}
                                          </Badge>
                                        ))}
                                      </Flex>
                                    </Flex>
                                  </Flex>
                                }
                              />{' '}
                            </Flex>
                          </Flex>
                          <Box
                            sx={{
                              px: 20
                            }}
                          >
                            {task.forms?.map((form, k) => {
                              const formResponses = task.responses?.filter(
                                r =>
                                  r?.__typename === 'FormResponse' &&
                                  r.form.id === form.id
                              )

                              const notDraftResponsesOrUnlinkedToStep = formResponses?.filter(
                                fr => !fr.draft && fr.stepId
                              )

                              return (
                                <Box key={k} sx={{ mb: '20px' }}>
                                  <Flex
                                    sx={{
                                      flexDirection: 'row',
                                      width: '100%',
                                      justifyContent: 'space-between',
                                      gap: '10px',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Flex
                                      sx={{
                                        fontWeight: 'bold',
                                        alignItems: 'center',
                                        gap: '10px',
                                        color: notDraftResponsesOrUnlinkedToStep?.length
                                          ? 'success'
                                          : ''
                                      }}
                                    >
                                      <TaskBullet
                                        complete={
                                          notDraftResponsesOrUnlinkedToStep?.length
                                        }
                                      />
                                      {form?.name}
                                    </Flex>

                                    {formResponses?.length < 1 && (
                                      <FormSubmitRendererModalButton
                                        modalButtonProps={{
                                          buttonLabel: 'Start',
                                          buttonProps: {
                                            variant: 'primary',
                                            icon: 'right arrow',
                                            iconPosition: 'right',
                                            size: 'sm'
                                          },
                                          onClose: refetch
                                        }}
                                        onFinish={refetch}
                                        formId={form?.id}
                                        stepId={task?.id}
                                      />
                                    )}
                                  </Flex>
                                  <Box
                                    sx={{
                                      my: '8px'
                                    }}
                                  >
                                    {formResponses?.map((fr, k) => {
                                      const isUnlinkedToStep = !fr?.stepId
                                      return (
                                        <>
                                          <FormSubmitRendererModalButton
                                            key={k}
                                            modalButtonProps={{
                                              CustomButtonComponent: p => {
                                                return (
                                                  <Flex
                                                    {...p}
                                                    sx={{
                                                      gap: '10px',
                                                      bg: 'gray200',
                                                      px: '10px',
                                                      py: '5px',
                                                      my: '5px',
                                                      justifyContent:
                                                        'space-between',
                                                      alignItems:
                                                        'center',
                                                      width: '100%',
                                                      flexWrap: 'wrap'
                                                    }}
                                                  >
                                                    <Flex
                                                      sx={{
                                                        gap: '5px'
                                                      }}
                                                    >
                                                      {fr.draft && (
                                                        <Badge
                                                          size="sm"
                                                          variant="warning"
                                                        >
                                                          Draft
                                                        </Badge>
                                                      )}
                                                      <Overline
                                                        sx={{
                                                          mb: '0px',
                                                          display:
                                                            'flex',
                                                          alignItems:
                                                            'center',
                                                          gap: '5px'
                                                        }}
                                                      >
                                                        {isUnlinkedToStep && (
                                                          <QuestionTooltip
                                                            iconHeight={
                                                              13
                                                            }
                                                            tip={
                                                              'This response is not linked to a workflow but looks like it may be a match for this task.  This means you may have submitted this outside the workflow interface.  Click the "Link to Step" to connect it to this unfinished task.'
                                                            }
                                                          />
                                                        )}
                                                        {
                                                          fr
                                                            .submittedBy
                                                            .name
                                                        }{' '}
                                                        <Box
                                                          sx={{
                                                            fontSize:
                                                              '10px'
                                                          }}
                                                        >
                                                          {prettyDate(
                                                            fr.createdAt
                                                          )}
                                                        </Box>
                                                      </Overline>
                                                      {fr.summary
                                                        .filter(
                                                          s => s
                                                        )
                                                        ?.map(
                                                          (s, k) => (
                                                            <Box
                                                              sx={{
                                                                borderRight:
                                                                  '1px solid',
                                                                borderRightColor:
                                                                  'gray200',
                                                                px:
                                                                  '8px'
                                                              }}
                                                              key={k}
                                                            >
                                                              <RichTextRender
                                                                sx={{
                                                                  fontSize:
                                                                    '12px',
                                                                  mb:
                                                                    '3px',
                                                                  mr:
                                                                    '3px'
                                                                }}
                                                                mw={[
                                                                  '300px',
                                                                  null,
                                                                  '130px'
                                                                ]}
                                                                text={
                                                                  s
                                                                }
                                                              />
                                                            </Box>
                                                          )
                                                        )}
                                                    </Flex>
                                                    <Flex
                                                      sx={{
                                                        gap: '5px'
                                                      }}
                                                    >
                                                      {isUnlinkedToStep && (
                                                        <StopPropogation>
                                                          <LinkResponseToStepModalButton
                                                            responseId={
                                                              fr.id
                                                            }
                                                            stepId={
                                                              task?.id
                                                            }
                                                            stepTimestamp={
                                                              task.timestamp
                                                            }
                                                            stepTitle={
                                                              task.title
                                                            }
                                                            variables={
                                                              variables
                                                            }
                                                          />
                                                        </StopPropogation>
                                                      )}
                                                      <Button
                                                        variant="secondary"
                                                        icon="pencil"
                                                        size="sm"
                                                      />
                                                    </Flex>
                                                  </Flex>
                                                )
                                              }
                                            }}
                                            fetchFormResponse
                                            formId={form?.id}
                                            formResponseId={fr.id}
                                          />
                                        </>
                                      )
                                    })}
                                  </Box>
                                </Box>
                              )
                            })}
                            {task.bulkSubmit && (
                              <Box sx={{ mb: '20px' }}>
                                <Flex
                                  sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Flex
                                    sx={{
                                      fontWeight: 'bold',
                                      alignItems: 'center',
                                      gap: '10px',
                                      color: notDraftBulkOrUnlinkedToStep?.length
                                        ? 'success'
                                        : ''
                                    }}
                                  >
                                    <TaskBullet
                                      complete={
                                        notDraftBulkOrUnlinkedToStep?.length
                                      }
                                    />
                                    Bulk Submission
                                  </Flex>

                                  {notDraftBulkOrUnlinkedToStep?.length <
                                    1 && (
                                    <ModalButton
                                      modalHeader={`Bulk Submission`}
                                      buttonProps={{
                                        iconPosition: 'right',
                                        icon: 'right arrow',

                                        size: 'sm',
                                        variant: 'primary'
                                      }}
                                      workflowStepId={task?.id}
                                      buttonLabel={'Start'}
                                      modalProps={{
                                        stickySubmit: true,
                                        shouldCloseOnEsc: false
                                      }}
                                      contentHeight={'90vh'}
                                      bodyStyles={{
                                        overflow: 'auto'
                                      }}
                                    >
                                      {({ toggle }) => {
                                        return (
                                          <BulkIncident
                                            hideDateInput
                                            hideResponderInputs
                                            stepId={task?.id}
                                            onFinish={() => {
                                              toggle()
                                              refetch()
                                            }}
                                          />
                                        )
                                      }}
                                    </ModalButton>
                                  )}
                                </Flex>
                                <Box
                                  sx={{
                                    my: '8px'
                                  }}
                                >
                                  {bulkSubmissions?.map(bs => {
                                    const isUnlinkedToStep = !bs?.stepId
                                    return (
                                      <ModalButton
                                        CustomButtonComponent={p => {
                                          return (
                                            <Flex
                                              {...p}
                                              sx={{
                                                gap: '10px',
                                                bg: 'gray200',
                                                px: '10px',
                                                py: '5px',
                                                my: '5px',
                                                justifyContent:
                                                  'space-between',
                                                alignItems: 'center',
                                                width: '100%'
                                              }}
                                            >
                                              <Overline
                                                sx={{
                                                  mb: '0px'
                                                }}
                                              >
                                                {
                                                  bs.breakdown[0]
                                                    ?.reporter?.name
                                                }{' '}
                                                at{' '}
                                                {prettyDate(
                                                  bs.createdAt
                                                )}
                                              </Overline>
                                              <Flex>
                                                {isUnlinkedToStep && (
                                                  <StopPropogation>
                                                    <LinkResponseToStepModalButton
                                                      responseId={
                                                        bs
                                                          .submissionIds[0]
                                                      }
                                                      stepId={
                                                        task?.id
                                                      }
                                                      stepTimestamp={
                                                        task.timestamp
                                                      }
                                                      stepTitle={
                                                        task.title
                                                      }
                                                      variables={
                                                        variables
                                                      }
                                                    />
                                                  </StopPropogation>
                                                )}
                                                <Button
                                                  variant="link"
                                                  icon="pencil"
                                                  size="sm"
                                                />
                                              </Flex>
                                            </Flex>
                                          )
                                        }}
                                      >
                                        {() => (
                                          <BulkSubmissionEditContent
                                            {...bs}
                                            dispatches={
                                              bs.breakdown[0]
                                                .resources || []
                                            }
                                            note={
                                              bs?.bulkNotes?.[1]
                                                ?.text ||
                                              bs?.bulkNotes?.[0]?.text
                                            }
                                            submissionIds={
                                              bs.submissionIds
                                            }
                                            breakdown={bs.breakdown}
                                            createdAt={bs.createdAt}
                                            incidentTime={
                                              bs.incidentTime
                                            }
                                            cacheKey={'myWorkflow'}
                                            cacheQuery={
                                              GET_HOMEBASE_TASKS
                                            }
                                            variables={variables}
                                            notes={bs.bulkNotes}
                                            id={bs.id}
                                          />
                                        )}
                                      </ModalButton>
                                    )
                                  })}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )
                  })}
                </Card>
              )
            })
          )}
        </Flex>
      )}
    </Box>
  )
}

const TaskBullet = ({ complete }) => {
  return (
    <Flex
      sx={{
        border: '1px solid',
        borderColor: 'gray200',
        width: '25px',
        height: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        bg: complete && 'success'
      }}
    >
      {complete ? (
        <>
          <ReactConfetti
            width={500}
            height={500}
            numberOfPieces={30}
            recycle={false}
            colors={presetColors}
          />

          <Icon
            color={'white'}
            icon="checkmark"
            width={12}
            height={12}
          />
        </>
      ) : (
        ''
      )}
    </Flex>
  )
}

const WhatsHappening = ({ currentAgency }) => {
  const navigate = useNavigate()
  const startDate = moment(new Date())
    .startOf('D')
    .toISOString()
  const endDate = moment(new Date())
    .endOf('D')
    .toISOString()

  const { data, loading, refetch } = useQuery(GET_HOMEBASE_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        incidentTime: {
          gt: startDate,
          lt: endDate
        },
        important: {
          equals: true
        }
      }
    }
  })

  const importantIncidents = get(data, 'myAgenciesSubmissions', [])

  const activeDispatches = get(
    data || [],
    'myAgenciesSubmissionsWithActiveDispatches',
    []
  )

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        flexDirection: 'column',
        margin: '0 0 0 auto',
        flex: 1,
        minWidth: '300px',
        p: 20,
        mb: ['50px', null, null, null, null, '0px']
      }}
    >
      <PayWallScreen hideContent featureLabel="dispatching">
        <Box sx={{ mb: 40, width: '100%' }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              py: '10px',
              height: '50px',
              alignItems: 'center'
            }}
          >
            <h5
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              Active Incidents{' '}
              <SubscriptionButton
                startOn
                minimal
                variables={{
                  agencyId: currentAgency.id
                }}
                query={DISPATCH_SUB}
                onSubscriptionData={() => {
                  // allow for cache to update...
                  setTimeout(() => refetch(), 300)
                }}
                externalLoading={loading}
              />
            </h5>
            <DispatchColorKey />
          </Flex>

          <Card>
            <ListGroup sx={{ padding: '1rem' }}>
              {activeDispatches && activeDispatches.length > 0 ? (
                activeDispatches.map(p => {
                  return (
                    <StyledListGroupItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/agency/dispatches')}
                      key={p.id}
                    >
                      <Flex
                        sx={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <ListGroupItemHeading>
                          {prettyDate(p.incidentTime, 'HH:mm')} -{' '}
                          {p.type && p.type.label}{' '}
                        </ListGroupItemHeading>

                        <ListGroupItemHeading>
                          {p.location &&
                            p.location.position &&
                            `${p.location.position.name}`}
                        </ListGroupItemHeading>
                      </Flex>
                      <Flex>
                        {p.dispatches.map(d => (
                          <DispatchStatusColor
                            style={{ marginRight: '3px' }}
                            oScene={d.onScene}
                            completedAt={d.completedAt}
                            enRoute={d.enRoute}
                          >
                            {d.unit ? d.unit.name : 'Unassigned'}
                          </DispatchStatusColor>
                        ))}
                      </Flex>
                    </StyledListGroupItem>
                  )
                })
              ) : (
                <Box>
                  <CenteredContent height={'200px'}>
                    {loading && <Spinner />}
                    No active calls{' '}
                  </CenteredContent>
                </Box>
              )}
            </ListGroup>
          </Card>
        </Box>
      </PayWallScreen>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Today's Notable Incidents</h5>
        <Box variant="card">
          <ImportantSubmissionList
            data={importantIncidents}
            loading={loading}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Weather</h5>
        <Box variant="card">
          <WeatherWidget
            {...currentAgency.settings}
            timezone={currentAgency.timezone}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HomeBase
