import { Fragment, use, useEffect, useState } from 'react'
import { Box, Flex } from 'theme-ui'

import { useQuery } from '@apollo/client'
import {
  GET_MY_INCIDENTS_IN_REVIEW_PROCESS,
  GET_MY_WORKFLOW_PINS,
  useGetCurrentAgencyContext
} from 'src/app/hooks/queries'

import Card from 'src/app/components/Shared/Elements/Card'
import { Overline } from 'src/app/components/Shared'
import Icon from 'src/app/components/Shared/Icon'
import ModalButton from 'src/app/components/Shared/ModalButton'
import ViewFileModal from 'src/app/components/Operations/Documents/ViewFileModal'

import { UrgencyIcon } from 'src/app/components/Bulletins/BulletinIcons'
import { BulletinModalContentContainer } from 'src/app/components/Bulletins/BulletinRichTextModal'
import CollapsablePanel from 'src/app/components/Shared/CollapsablePanel'
import Button from 'src/app/components/Shared/Elements/Button'

import { SubmissionItem } from 'src/app/components/Stats/SubmissionList'
import { PulingIndicator } from 'src/app/components/Shared/PulsingIndicator'

import ViewFileUrlModalContent from 'src/app/components/Operations/Documents/ViewFileUrlModalContent'
import Spinner from 'src/images/icons/Spinner'
import { CenteredContent } from 'src/app/components'
import { prettyDate } from 'src/utils'

export const SupervisorReviewPin = ({ hideOnEmpty }) => {
  const { user } = useGetCurrentAgencyContext()
  const { data } = useQuery(GET_MY_INCIDENTS_IN_REVIEW_PROCESS)

  const incidents = data?.myIncidentsInReviewProcess || []

  if (!incidents?.length && hideOnEmpty) return <Fragment />

  const IncidentItem = (submission, index) => (
    <SubmissionItem
      key={index}
      sub={submission}
      requestReviewOnly={user?.role === 'USER'}
      refetchQueries={['GetMyIncidentsInReviewProcess']}
    />
  )
  const totalIncidentsInReview =
    incidents?.mySupervisorIncidents?.length +
    incidents?.myReportedIncidents?.length

  return (
    <CollapsibleSection
      title="Supervisor Reviews"
      count={totalIncidentsInReview}
      itemsToReview={incidents?.mySupervisorIncidents}
      reviewedItems={incidents?.myReportedIncidents}
      ItemComponent={IncidentItem}
      needsActionTitle="Under my Supervision"
      reviewedActionTitle="My Reported Incidents"
      showOthersDefault={incidents?.myReportedIncidents?.length > 0}
      emptyState="No incidents to review.  You can find reviewed incidents in your Activity Log."
    />
  )
}

export function WorkflowPins() {
  const { data } = useQuery(GET_MY_WORKFLOW_PINS)

  if (!data) return <Spinner centered containerHeight={100} />

  const files = data?.myWorkflow?.files || []
  const bulletins = data?.myWorkflow?.bulletins || []

  const filesToReview = files.filter(f => !f.hasRead)
  const bulletinsToReview = bulletins.filter(b => !b.hasRead)

  const reviewedFiles = files.filter(f => f.hasRead)
  const reviewedBulletins = bulletins.filter(b => b.hasRead)

  return (
    <Card sx={{ width: '100%' }}>
      <CollapsibleSection
        title="Bulletins"
        count={bulletinsToReview.length}
        itemsToReview={bulletinsToReview}
        reviewedItems={reviewedBulletins}
        ItemComponent={BulletinWorkflowItem}
      />

      <CollapsibleSection
        title="Documents"
        count={filesToReview.length}
        itemsToReview={filesToReview}
        reviewedItems={reviewedFiles}
        ItemComponent={FileWorkflowItem}
      />
      <SupervisorReviewPin />
    </Card>
  )
}

const BulletinWorkflowItem = bulletin => {
  return (
    <Flex
      sx={{
        width: '100%',
        gap: 10,
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: '10px'
        }}
      >
        <UrgencyIcon urgency={bulletin.urgency} />{' '}
        <Box sx={{ mb: '5px' }}>
          <Box>
            {bulletin.title}
            <Overline>
              {bulletin?.author?.name} at{' '}
              {prettyDate(bulletin.createdAt)}
            </Overline>
          </Box>
        </Box>
      </Flex>
      <ModalButton
        buttonLabel={bulletin.hasRead ? 'Complete' : 'View'}
        buttonProps={{
          variant: bulletin.hasRead ? 'success' : 'primary',
          icon: bulletin.hasRead ? 'checkmark' : 'right arrow',
          iconPosition: 'right',
          size: 'sm'
        }}
      >
        {({ toggle }) => {
          return (
            <BulletinModalContentContainer
              id={bulletin.id}
              onClose={toggle}
            />
          )
        }}
      </ModalButton>
    </Flex>
  )
}

const FileWorkflowItem = file => {
  const { id, name, url, createdBy, hasRead, ...rest } = file
  return (
    <Flex
      sx={{
        width: '100%',
        gap: 10,
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
      {...rest}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: '10px'
        }}
      >
        <Icon icon="pin" width={15} height={15} />{' '}
        <Box sx={{ mb: '5px' }}>
          {name}
          <Overline>{createdBy?.name}</Overline>
        </Box>
      </Flex>
      {url ? (
        <ModalButton
          buttonProps={{
            variant: hasRead ? 'success' : 'primary',
            icon: hasRead ? 'checkmark' : 'right arrow',
            iconPosition: 'right',
            size: 'sm'
          }}
          buttonLabel={hasRead ? 'Complete' : 'View'}
          contentHeight={'92vh'}
          href={url}
          contentSize={'lg'}
        >
          {() => {
            return <ViewFileUrlModalContent file={file} />
          }}
        </ModalButton>
      ) : (
        <ModalButton
          buttonLabel={hasRead ? 'Complete' : 'View'}
          buttonProps={{
            variant: hasRead ? 'success' : 'primary',
            icon: hasRead ? 'checkmark' : 'right arrow',
            iconPosition: 'right',
            size: 'sm'
          }}
        >
          {({ toggle }) => {
            return <ViewFileModal closeModal={toggle} f={file} />
          }}
        </ModalButton>
      )}
    </Flex>
  )
}

function CollapsibleSection({
  title,
  count,
  itemsToReview,
  reviewedItems = [],
  ItemComponent,
  needsActionTitle = 'Need Action',
  reviewedActionTitle,
  showOthersDefault,
  emptyState
}) {
  const [showOthers, setShowOthers] = useState(
    showOthersDefault ||
      (itemsToReview?.length < 1 && reviewedItems?.length > 0)
  )

  useEffect(() => {
    setShowOthers(showOthersDefault)
  }, [showOthersDefault])

  return (
    <Flex
      sx={{
        gap: '10px',
        flexDirection: 'column',
        flexWrap: 'wrap',
        border: '1px solid',
        borderColor: 'gray200'
      }}
    >
      <CollapsablePanel
        maxHeight={'auto'}
        containerStyle={{
          maxWidth: '100%'
        }}
        buttonOptions={{
          text: (
            <Flex>
              {title} {count ? `(${count})` : ''}{' '}
              {count > 0 && (
                <Box
                  sx={{
                    marginLeft: '10px'
                  }}
                >
                  <PulingIndicator color="red" />
                </Box>
              )}
            </Flex>
          ),
          openIcon: 'down arrow',
          closeIcon: 'up arrow',
          style: {
            borderBottom: 'none'
          }
        }}
      >
        {() => (
          <Flex
            sx={{
              gap: '10px',
              flexDirection: 'column',
              flexWrap: 'wrap',
              // p: '10px',
              maxWidth: '100%'
            }}
          >
            {itemsToReview?.length > 0 ? (
              <Flex
                sx={{
                  mb: '15px',
                  flexDirection: 'column',
                  gap: '5px',
                  maxWidth: '100%'
                }}
              >
                <h6 sx={{ mb: '10px' }}>
                  <strong>{needsActionTitle}</strong>
                </h6>
                {itemsToReview.map((item, index) => (
                  <ItemComponent key={index} {...item} />
                ))}
              </Flex>
            ) : (
              !showOthers && (
                <CenteredContent height={120}>
                  {emptyState || `No ${title} To Review`}
                </CenteredContent>
              )
            )}

            {reviewedItems?.length < 1 ? (
              <Fragment />
            ) : !showOthers ? (
              <Button
                variant="link"
                onClick={() => setShowOthers(!showOthers)}
                sx={{
                  p: '0px'
                }}
              >
                Show reviewed items
              </Button>
            ) : (
              <Flex
                sx={{
                  gap: '10px',
                  flexDirection: 'column',
                  alignItems: 'stretch'
                }}
              >
                <h6 sx={{ mb: '10px' }}>
                  <strong>
                    {reviewedActionTitle || `Reviewed ${title}`}
                  </strong>
                </h6>
                {reviewedItems.map((item, index) => (
                  <Box
                    sx={{
                      maxWidth: '100%'
                    }}
                  >
                    <ItemComponent key={index} {...item} />
                  </Box>
                ))}
              </Flex>
            )}
          </Flex>
        )}
      </CollapsablePanel>
    </Flex>
  )
}
