/** @jsxImportSource theme-ui */

import { Fragment } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import FormError, { cleanGraphQLError } from '../Forms/FormError'

import Spinner from 'src/images/icons/Spinner'
import CustomSelectInput from '../Forms/CustomSelectInput'
import { useToast } from 'src/components/toasts'
import { USER_ROLES } from '../../services/auth'
import useForm from '../../hooks/useForms'
import {
  MY_SUPERVISION_CONFIG,
  useGetCurrentAgencyContext
} from '../../hooks/queries'

import { validateEmail } from 'src/utils/validations'
import { SEND_INVITE } from 'src/app/hooks/mutations'

import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import ModalFooter from '../Shared/Elements/ModalFooter'
import Input from '../Shared/Elements/Input'
import FormGroup from '../Shared/Elements/FormGroup'
import Switch from '../Shared/Elements/Switch'

import { useConfirm } from 'src/app/hooks/useConfirm'
import Form from '../Shared/Elements/Form'
import PhoneInput from '../Shared/Forms/PhoneInput'
import ModalBody from '../Shared/Elements/ModalBody'
import groupBy from 'lodash/groupBy'
import { Box, Flex } from 'theme-ui'
import InputGroupText from '../Shared/Elements/InputGroupText'

const ARCHIVE_USER = gql`
  mutation ToggleUserOnRoster($id: ID!) {
    toggleUserOnRoster(id: $id) {
      id
      rosterStatus
    }
  }
`

const UPDATE_USER = gql`
  mutation UpdateUser(
    $where: UserWhereUniqueInput!
    $data: UserUpdateInput!
  ) {
    updateOneUser(where: $where, data: $data) {
      id
      role
      email
      name
      phoneNumber
    }
  }
`

const UPDATE_USER_EMAIL = gql`
  mutation UpdateUserEmail($id: ID!, $newEmail: String!) {
    requestEmailUpdate(id: $id, newEmail: $newEmail) {
      id
      email
    }
  }
`

const UPDATE_USER_PHONE = gql`
  mutation UpdateUserPhone($id: ID!, $newPhoneNumber: String!) {
    updateUserPhone(id: $id, newPhoneNumber: $newPhoneNumber) {
      id
      phoneNumber
    }
  }
`

const RE_SEND_INVITE = gql`
  mutation ResendInvite($email: String!) {
    resendInviteEmail(email: $email) {
      success
    }
  }
`

export const InviteCurrentUserForm = ({
  userId,
  agencyId,
  onFinish
}) => {
  const [sendInvite, { loading, error }] = useMutation(SEND_INVITE, {
    refetchQueries: ['CurrentAgency']
  })
  const { values, handleChange, handleSubmit } = useForm(
    inviteCurrentUser
  )

  const { add } = useToast()

  async function inviteCurrentUser() {
    try {
      const res = await sendInvite({
        variables: {
          userId,
          email: values.email.trim(),
          role: values.role || 'USER',
          agencyId
        }
      })

      if (res && !res.errors) {
        add({
          content: 'User invited',
          color: 'success'
        })
        onFinish && onFinish()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Email</Label>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            value={values.email}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label>Role</Label>
          <Input
            onChange={handleChange}
            type="select"
            name="role"
            id="role"
            defaultValue={values.role || USER_ROLES[0]}
          >
            {USER_ROLES.map(r => (
              <option value={r}>
                {r.toLowerCase().replace('_', ' ')}
              </option>
            ))}
          </Input>
        </FormGroup>

        {error && <FormError error={error} />}
        <ModalFooter onClose={onFinish}>
          <Button disabled={!values.email || loading} type="submit">
            {loading ? 'Sending Invite..' : 'Invite'}
          </Button>
        </ModalFooter>
      </Form>
    </>
  )
}

export const UserToggleStatus = ({
  userId,
  currentStatus,
  label = '',
  currentUserId
}) => {
  const { isConfirmed } = useConfirm()
  const [toggleUserOnRoster, { error, loading }] = useMutation(
    ARCHIVE_USER
  )

  if (userId === currentUserId) {
    return <Fragment />
  }

  const isArchived = currentStatus === 'ARCHIVED'

  return (
    <div>
      <Switch
        id={`archive` + userId}
        name="archive"
        label={loading ? <Spinner /> : label}
        checked={isArchived ? false : true}
        readOnly
        onClick={async () => {
          let confirm = true
          if (!isArchived) {
            confirm = await isConfirmed(
              `Your are about to deactivate this user.  This means the user will not be able to log in or be selected as a responder to an incident.  Don't worry you will be able to reactivate. Are you sure you want to do this?`
            )
          }

          if (!confirm) {
            return
          }

          toggleUserOnRoster({
            variables: {
              id: userId
            }
          })
        }}
      />

      {error && <FormError error={error} />}
    </div>
  )
}

const TOGGLE_USER_IN_SERVICE = gql`
  mutation ToggleUserOnTheJob($id: ID!) {
    toggleUserOnTheJob(id: $id) {
      id
      rosterStatus
    }
  }
`

export const UserToggleOnTheJob = ({
  userId,
  currentStatus,
  label = ''
}) => {
  const [toggleUserOnTheJob, { error, loading }] = useMutation(
    TOGGLE_USER_IN_SERVICE
  )

  const isOnTheJob = currentStatus === 'ONTHEJOB'

  return (
    <div>
      <Switch
        style={{ cursor: 'pointer' }}
        id={`userToggle` + userId}
        name="archive"
        label={loading ? <Spinner /> : label}
        checked={isOnTheJob ? true : false}
        readOnly
        onClick={() => {
          toggleUserOnTheJob({
            variables: {
              id: userId
            }
          })
        }}
      />

      {error && <FormError error={error} />}
    </div>
  )
}

export const UserRoleDropdown = ({
  userId,
  currentRole = {},
  ...props
}) => {
  const { user } = useGetCurrentAgencyContext()
  const { id: myId } = user
  const [updateUserRole, { loading, error }] = useMutation(
    UPDATE_USER
  )
  const { add } = useToast()
  return (
    <div {...props}>
      <CustomSelectInput
        id="userRole"
        name="userRole"
        loading={loading}
        value={
          currentRole
            ? {
                value: currentRole,
                label: currentRole
              }
            : {}
        }
        onChange={async e => {
          try {
            if (myId === userId) {
              add({
                color: 'danger',
                content: "Can't update your own role."
              })
              return
            }
            const res = await updateUserRole({
              variables: {
                where: { id: userId },
                data: { role: e.value }
              }
            })

            if (res && !res.errors) {
              add({
                color: 'success',
                content: 'Role updated'
              })
            }
          } catch (e) {
            console.log(e)
            add({
              color: 'danger',
              content: cleanGraphQLError(e)
            })
          }
        }}
        options={USER_ROLES.map(o => ({
          label: o,
          value: o
        }))}
      />

      {error && <FormError error={error} />}
    </div>
  )
}

export const UserPhoneNumberInput = ({
  userId,
  currentNumber,
  onFinish,
  mfaEnabled = false
}) => {
  const { values, setValues, handleSubmit } = useForm(onSubmit, {
    phoneNumber: currentNumber
  })

  const [updateUserPhone, { loading }] = useMutation(
    UPDATE_USER_PHONE
  )
  const { add } = useToast()

  async function onSubmit() {
    try {
      if (!values.phoneNumber) {
        add({
          color: 'danger',
          content: 'Must provide a new number'
        })
        return
      }

      const res = await updateUserPhone({
        variables: {
          id: userId,
          newPhoneNumber: values.phoneNumber
        }
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content:
            "Your phone number has been updated.  We don't verify numbers, so make sure you typed it correctly.."
        })
        setValues({
          phoneNumber: res?.data?.updateUserPhone?.phoneNumber
        })
        onFinish && onFinish()
      } else {
        add({
          color: 'danger',
          content: cleanGraphQLError(res.errors[0])
        })
        return
      }
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: cleanGraphQLError(e)
      })
    }
  }

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'flex-end',

        minWidth: '120px',
        flexDirection: 'column',
        gap: '3px'
      }}
      onSubmit={handleSubmit}
    >
      <PhoneInput
        id="phoneNumber"
        name="phoneNumber"
        value={values.phoneNumber}
        onChange={val => {
          setValues(prev => ({
            phoneNumber: val || prev.phoneNumber || null
          }))
        }}
        disabled={mfaEnabled}
      />

      {values.phoneNumber !== currentNumber && (
        <Button variant="primary">
          {loading ? <Spinner white /> : 'Save'}
        </Button>
      )}
    </Form>
  )
}

export const UsernameInput = ({ userId, currentName = '' }) => {
  const { isConfirmed } = useConfirm()
  const { values, setValues, handleSubmit, isDirty } = useForm(
    onSubmit,
    {
      name: currentName
    }
  )

  const [updateUser, { error, loading }] = useMutation(UPDATE_USER)
  const { add } = useToast()

  async function onSubmit() {
    try {
      const confirm = await isConfirmed(
        `Your are about to change a user's name.  This means all incidents historically will now reference this name.  Are you sure you want to do this?`
      )

      if (!confirm) {
        return
      }

      const res = await updateUser({
        variables: {
          where: { id: userId },
          data: { name: values.name }
        }
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content: 'Name updated'
        })
      }
      setValues({}, true)
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: cleanGraphQLError(e)
      })
    }
  }

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        minWidth: '120px'
      }}
      onSubmit={handleSubmit}
    >
      <Input
        id="name"
        name="name"
        defaultValue={values.name}
        value={values.name}
        onChange={event => {
          const val = event.target.value

          setValues(prev => ({
            name: val || ''
          }))
        }}
      />
      {isDirty && values.name !== currentName && (
        <Button variant="primary">
          {loading ? <Spinner white /> : 'Save'}
        </Button>
      )}
      {error && <FormError error={error} />}
    </Form>
  )
}

export const UserEmailInput = ({
  userId,
  currentEmail = '',
  onFinish
}) => {
  const { isConfirmed } = useConfirm()
  const { values, setValues, handleSubmit, isDirty } = useForm(
    onSubmit,
    {
      email: currentEmail
    }
  )
  const [updateUser, { loading }] = useMutation(UPDATE_USER_EMAIL, {
    refetchQueries: ['GetUsersSignedUp']
  })
  const { add } = useToast()

  async function onSubmit() {
    try {
      const confirm = await isConfirmed(
        `Your are about to change a user's email.  This means all incidents historically will now reference this email.  Are you sure you want to do this?`
      )

      if (!confirm) {
        return
      }

      if (!validateEmail(values.email)) {
        add({
          content: 'Must be a valid email address',
          color: 'danger'
        })
        return
      }

      const res = await updateUser({
        variables: {
          id: userId,
          newEmail: values.email
        }
      })

      if (res && !res.errors) {
        add({
          color: 'success',
          content:
            'An email has been sent to the new address, please confirm.'
        })
        onFinish && onFinish()
      } else {
        console.log(res.errors)
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
        return
      }
      setValues({}, true)
    } catch (e) {
      console.log(e)
      add({
        color: 'danger',
        content: cleanGraphQLError(e)
      })
    }
  }

  return (
    <Form
      style={{
        display: 'flex',
        alignItems: 'center',
        minWidth: '120px'
      }}
      onSubmit={handleSubmit}
    >
      <Input
        id="name"
        name="name"
        defaultValue={values.email}
        value={values.email}
        onChange={event => {
          const val = event.target.value

          setValues(() => ({
            email: val || ''
          }))
        }}
      />

      {isDirty && values.email !== currentEmail && (
        <Button variant="primary">
          {loading ? <Spinner white /> : 'Save'}
        </Button>
      )}
    </Form>
  )
}

export const UserResendInviteButton = ({ email, ...props }) => {
  const { add } = useToast()
  const [resendInvite, { loading: resendingInvite }] = useMutation(
    RE_SEND_INVITE
  )
  const onResendInvite = async args => {
    try {
      const res = await resendInvite(args)

      if (
        res.data &&
        res.data.resendInviteEmail &&
        res.data.resendInviteEmail.success
      ) {
        add({
          content: `Invite resent to ${email}!`,
          color: 'success'
        })
      }
    } catch (e) {
      add({
        color: 'danger',
        content: cleanGraphQLError(e)
      })
    }
  }

  return (
    <Button
      onClick={() =>
        onResendInvite({
          variables: {
            email
          }
        })
      }
      variant="link"
      {...props}
    >
      {resendingInvite ? 'Resending..' : 'Resend Invite'}
    </Button>
  )
}

export const TOGGLE_USER_HIDE_FROM_PULSE = gql`
  mutation ToggleUserHideFromPulse($id: ID!) {
    toggleUserHideFromPulse(id: $id) {
      id
      hideFromPulse
    }
  }
`

export const UserToggleHideFromPulse = ({
  userId,
  hideFromPulse,
  label = '',
  ...rest
}) => {
  const { add } = useToast()
  const [toggleUserHideFromPulse, { error, loading }] = useMutation(
    TOGGLE_USER_HIDE_FROM_PULSE
  )

  return (
    <div {...rest}>
      <Switch
        style={{ cursor: 'pointer' }}
        id={`locationToggle` + userId}
        name="archive"
        label={loading ? <Spinner /> : hideFromPulse ? 'On' : 'Off'}
        checked={hideFromPulse ? true : false}
        readOnly
        onClick={async () => {
          await toggleUserHideFromPulse({
            variables: {
              id: userId
            }
          })

          add({
            content: `User is now set as ${
              !hideFromPulse ? 'show' : 'hide'
            } from Pulse`,
            color: 'success'
          })
        }}
      />

      {error && <FormError error={error} />}
    </div>
  )
}

export const SupervisorConfigFormModal = ({
  onFinish,
  userId,
  userName
}) => {
  const {
    users,
    positions,
    positionCategories
  } = useGetCurrentAgencyContext()
  const { data, loading, error } = useQuery(MY_SUPERVISION_CONFIG, {
    variables: {
      supervisorUserId: userId
    },
    fetchPolicy: 'cache-and-network'
  })
  if (loading) {
    return <Spinner centered />
  }

  if (error) {
    return cleanGraphQLError(error.message)
  }
  const supervisorReviewsAndOptions = Object.entries(
    groupBy(
      data?.myAgenciesSupervisorReviewStateOptions,
      t => t?.supervisorReviewConfig?.form?.id
    )
  ).map(function([key, items]) {
    const currentCat = data?.myAgenciesSupervisorReviewStateOptions.find(
      t => t?.supervisorReviewConfig?.form?.id === key
    )
    return {
      label: currentCat?.supervisorReviewConfig?.form?.name,
      options: items
        .filter(i => !i.finalReviewState)
        .map(opt => {
          return opt
        })
    }
  })

  return (
    <ModalBody>
      <SupervisorConfigForm
        userName={userName}
        users={users}
        positions={positions}
        groups={positionCategories}
        supervisorConfig={data?.supervisorConfig}
        supervisorReviewsAndOptions={supervisorReviewsAndOptions}
        userId={userId}
        onFinish={onFinish}
      />
    </ModalBody>
  )
}

const UPDATE_SUPERVISOR_CONFIG = gql`
  mutation UpdateSupervisorConfig(
    $supervisorUserId: ID!
    $supervisedUsers: [ID!]
    $supervisedPositions: [ID!]
    $supervisedGroups: [ID!]
    $textNotifications: Boolean
    $emailNotifications: Boolean
    $subscribedStateOptions: [ID!]
    $allSupervised: Boolean
  ) {
    updateSupervisorConfig(
      supervisorUserId: $supervisorUserId
      supervisedUsers: $supervisedUsers
      supervisedPositions: $supervisedPositions
      supervisedGroups: $supervisedGroups
      textNotifications: $textNotifications
      emailNotifications: $emailNotifications
      subscribedStateOptions: $subscribedStateOptions
      allSupervised: $allSupervised
    ) {
      id
      textNotifications
      emailNotifications
      subscribedStateOptions {
        id
        state
      }
      allSupervised
      supervisedUsers {
        id
        name
      }
      supervisedPositions {
        id
        name
      }
      supervisedGroups {
        id
        name
        color
      }
    }
  }
`

const SupervisorConfigForm = ({
  users,
  positions,
  groups,
  userId,
  supervisorConfig,
  supervisorReviewsAndOptions,
  onFinish,
  userName
}) => {
  const { user } = useGetCurrentAgencyContext()
  const [updateSupervisorConfig, { loading: updating }] = useMutation(
    UPDATE_SUPERVISOR_CONFIG,
    {
      refetchQueries: [
        {
          query: MY_SUPERVISION_CONFIG,
          variables: {
            supervisorUserId: userId
          }
        }
      ]
    }
  )

  const { add } = useToast()

  const { values, setValues, handleSubmit } = useForm(onSubmit, {
    supervisorUserId: userId,
    allSupervised: supervisorConfig?.allSupervised,
    supervisedGroups: supervisorConfig?.supervisedGroups,
    supervisedPositions: supervisorConfig?.supervisedPositions,
    supervisedUsers: supervisorConfig?.supervisedUsers,
    textNotifications: supervisorConfig?.textNotifications,
    emailNotifications: supervisorConfig?.emailNotifications,
    subscribedStateOptions: supervisorConfig?.subscribedStateOptions
  })

  async function onSubmit() {
    try {
      let res
      res = await updateSupervisorConfig({
        variables: {
          supervisorUserId: userId,
          textNotifications: values.textNotifications,
          emailNotifications: values.emailNotifications,
          subscribedStateOptions: values?.subscribedStateOptions?.map(
            so => so.id
          ),
          allSupervised: values.allSupervised,
          ...(values.supervisedUsers && {
            supervisedUsers: values.supervisedUsers.map(u => u.id)
          }),
          ...(values.supervisedPositions && {
            supervisedPositions: values.supervisedPositions.map(
              u => u.id
            )
          }),
          ...(values.supervisedGroups && {
            supervisedGroups: values.supervisedGroups.map(u => u.id)
          })
        }
      })

      if (res.errors) {
        add({
          content: cleanGraphQLError(res.errors[0]),
          color: 'danger'
        })
        return
      } else {
        add({
          content: 'Supervisor Config Saved',
          color: 'success'
        })
        onFinish && onFinish()
      }
    } catch (e) {
      console.log(e)
      add({
        content: cleanGraphQLError(e),
        color: 'danger'
      })
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Flex
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <h6>
          Who {userName || 'I'} Supervise{userName ? 's' : ''}
        </h6>
        <Switch
          id="allSupervised"
          name="allSupervised"
          label={'Supervise all'}
          checked={values.allSupervised}
          readOnly
          onClick={() => {
            setValues({
              ...values,
              supervisedGroups: [],
              supervisedUsers: [],
              supervisedPositions: [],
              allSupervised: !values.allSupervised
            })
          }}
        />
      </Flex>
      <FormGroup
        sx={{
          mt: '20px'
        }}
      >
        <Label>Users</Label>
        <CustomSelectInput
          id="supervisedUsers"
          name="supervisedUsers"
          isMulti={true}
          isDisabled={values.allSupervised}
          getOptionValue={v => v.id}
          getOptionLabel={v => v.name}
          value={values.supervisedUsers}
          onChange={selected => {
            setValues({
              ...values,
              allSupervised: false,
              supervisedUsers: selected
            })
          }}
          options={users?.filter(u => u.id !== userId)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Resources/Locations</Label>
        <CustomSelectInput
          id="supervisedPositions"
          name="supervisedPositions"
          isMulti={true}
          getOptionValue={v => v.id}
          getOptionLabel={v => v.name}
          isDisabled={values.allSupervised}
          value={values.supervisedPositions}
          onChange={selected => {
            setValues({
              ...values,
              allSupervised: false,
              supervisedPositions: selected
            })
          }}
          options={positions}
        />
      </FormGroup>
      <FormGroup>
        <Label>Groups</Label>
        <CustomSelectInput
          id="supervisedGroups"
          name="supervisedGroups"
          withColor
          isMulti={true}
          getOptionValue={v => v.id}
          getOptionLabel={v => v.name}
          value={values.supervisedGroups}
          isDisabled={values.allSupervised}
          onChange={selected => {
            setValues({
              ...values,
              allSupervised: false,
              supervisedGroups: selected
            })
          }}
          options={groups}
        />
      </FormGroup>

      <h6>{userName ? `${userName}'s` : 'My'} Review States</h6>
      <Box
        sx={{
          p: 3,
          mt: '10px',
          bg: 'gray300'
        }}
      >
        <FormGroup>
          <Label>Show Incidents With These States</Label>
          <CustomSelectInput
            id="subscribedStateOptions"
            name="subscribedStateOptions"
            isMulti={true}
            getOptionValue={v => v.id}
            getOptionLabel={v => `${v.state} `}
            value={values.subscribedStateOptions}
            onChange={selected => {
              setValues({
                ...values,
                subscribedStateOptions: selected
              })
            }}
            options={supervisorReviewsAndOptions || []}
          />
          <InputGroupText>
            Leaving this blank will result in only getting alerted for
            Ready for Review state, if applicable.
          </InputGroupText>
        </FormGroup>
        <h6
          sx={{
            my: '10px'
          }}
        >
          Notify {userName || 'me'} via
        </h6>

        <FormGroup>
          <Switch
            id="emailNotifications"
            name="emailNotifications"
            label={'Send email notifications'}
            checked={values.emailNotifications}
            readOnly
            onClick={() => {
              setValues({
                ...values,
                emailNotifications: !values.emailNotifications
              })
            }}
          />
        </FormGroup>
        <FormGroup>
          <Switch
            id="textNotifications"
            name="textNotifications"
            label={`Send SMS (Free Trial)`}
            disabled={!user?.phoneNumber}
            checked={values.textNotifications}
            readOnly
            onClick={() => {
              setValues({
                ...values,
                textNotifications: !values.textNotifications
              })
            }}
          />
        </FormGroup>
      </Box>

      <ModalFooter
        onClose={() => {
          onFinish && onFinish()
        }}
      >
        <Button
          type="submit"
          variant="primary"
          icon={updating && 'spinner'}
        >
          Save
        </Button>
      </ModalFooter>
    </Form>
  )
}
