import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import { Fragment, useEffect } from 'react'
import { Box, Flex } from 'theme-ui'

import { createActivtyTypeLabel } from 'src/utils/dataMapping'

import StickyButton from '../Shared/StickyButton'

import FormAttachmentButtonList from '../Shared/Forms/FormAttachmentButton'
import ModalButton from '../Shared/ModalButton'
import RichTextInput from '../Shared/RichTextInput'
import AddVehicleInputs from './AddVehicleInputs'
import CustomRadioButtonsInput from './CustomRadioButtonsInput'

import DatePickerInput from './DatePickerInput'
import DemographicDataInputs from './DemographicDataInputs'
import GoogleMapInput from './GoogleMapInput'

import { AddResourceInputs } from './AddResourceInputs'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import ListGroupItem from '../Shared/Elements/ListGroupItem'

import FormGroup from '../Shared/Elements/FormGroup'
import InputGroupText from '../Shared/Elements/InputGroupText'

import EditLocationModal from './EditLocationInputs'
import uniqueId from 'lodash/uniqueId'
import QuestionTooltip from '../Shared/QuestionTooltip'

import { EditWeatherLogModalForm } from './EditWeatherLogModalForm'
import { Divider } from '../Shared'
import { UppyFileUploadButton } from '../Shared/Uppy/AuthenticatedUppyFilePicker'

const SubmissionReviewFields = ({
  values,
  handleSelect,
  handleChange,
  mergeValues,
  submittable,
  currentAgency,
  loading,
  buttonOptions,
  hideFields = [],
  useTodaysPosition,
  useNoteTemplate
}) => {
  const isEditing = values?.id

  const currentVehicles = get(values, 'vehicles', [])
  const hasVehicles =
    currentVehicles.filter(i => !i.removed).length > 0

  const files = get(values, 'files', [])

  const dispatches = get(values, 'dispatches', null)

  const involvedPartyInfo = get(values, 'involvedPartyInfo', null)
  const nonDeletedIP = !isEmpty(involvedPartyInfo)
    ? involvedPartyInfo.filter(ip => !ip.deleted)
    : undefined

  const nonDeletedIPNotEmpty =
    nonDeletedIP &&
    nonDeletedIP.filter(i => {
      const { id, ...rest } = i

      return !isEmpty(rest)
    })
  const hasDemoGraphics =
    nonDeletedIPNotEmpty && !isEmpty(nonDeletedIPNotEmpty)
  // Need to make this accept multip responder/position groups... map them out

  const currentType = currentAgency.activities.find(
    a =>
      a.label ===
      createActivtyTypeLabel(
        values.subLabelOne,
        values.subLabelTwo,
        values.subLabelThree
      )
  )
  const noteTemplate = currentType && currentType.noteTemplate

  useEffect(() => {
    useNoteTemplate &&
      noteTemplate &&
      !values.note &&
      mergeValues(
        {
          note: noteTemplate
        },
        true
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const locationPosition = currentAgency?.positions?.find(
    l => l.id === values.location?.position?.value
  )

  return [
    <Box key={1} sx={{ width: '100%' }}>
      {!hideFields.includes('incidentTime') && (
        <FormGroup>
          <Label htmlFor="incidentTime">Incident Time</Label>

          <DatePickerInput
            onChange={date => {
              handleSelect({
                name: 'incidentTime',
                value: date
              })
            }}
            showTimeSelect={true}
            name="incidentTime"
            id="incidentTime"
            value={values.incidentTime || new Date()}
            placeholder="Time of incident"
            timeIntervals={5}
          />
          <Divider margin={'15px'} key={200} />
        </FormGroup>
      )}

      {!hideFields.includes('position') &&
        !hideFields.includes('responder') && (
          <FormGroup>
            <Label htmlFor="resources">Resources</Label>

            <AddResourceInputs
              dispatches={dispatches}
              mergeValues={mergeValues}
              values={values}
              useTodaysPosition={useTodaysPosition}
              renderHelperText={{
                0: !hideFields.includes('missingUnit') && (
                  <InputGroupText>
                    <strong>
                      Your dispatch was unassigned. Add the resource
                      and personnel here:
                    </strong>
                  </InputGroupText>
                )
              }}
            />
            <Divider margin={'15px'} key={200} />
          </FormGroup>
        )}
      {!hideFields.includes('location') && (
        <FormGroup>
          <GoogleMapInput
            key={4}
            showPrettyAddress={values.location?.prettyAddress}
            inputProps={{
              name: 'location',
              id: 'location',
              label: 'Incident Location / Coordinates'
            }}
            value={values.location}
            handleSelect={data =>
              handleSelect({ name: 'location', value: data })
            }
            handleChange={data =>
              handleChange(null, { name: 'location', ...data })
            }
            startingLat={locationPosition?.lat}
            startingLng={locationPosition?.lng}
          />

          <InputGroupText style={{ marginTop: '5px' }}>
            {(values?.location?.position?.name ||
              values?.location?.position?.label) && (
              <>
                Near
                <strong style={{ marginLeft: '5px' }}>
                  {values.location.position.label ||
                    values.location.position.name}
                </strong>
              </>
            )}

            <ModalButton
              modalHeader={'Edit Location'}
              buttonLabel={
                values?.location?.position?.name ||
                values?.location?.position?.label
                  ? ''
                  : 'Choose saved location'
              }
              buttonProps={{
                variant: 'link',
                icon:
                  values?.location?.position?.name ||
                  values?.location?.position?.label
                    ? 'pencil'
                    : 'HQ',
                sx: { py: '3px' }
              }}
            >
              {({ toggle }) => {
                return (
                  <EditLocationModal
                    values={values}
                    mergeValues={mergeValues}
                    toggle={toggle}
                  />
                )
              }}
            </ModalButton>
          </InputGroupText>
          <Divider margin={'15px'} key={200} />
        </FormGroup>
      )}
      {!hideFields.includes('count') && (
        <FormGroup
          sx={{
            marginBottom: '0px'
          }}
        >
          <Label htmlFor="count">Victims / Involved</Label>
          <CustomRadioButtonsInput
            id="count"
            type="number"
            name="count"
            placeholder="Number"
            onChange={handleChange}
            value={values.count}
            handleSelect={handleSelect}
            options={involvedPartyOptions}
            minValue={0}
            withInput
          />
        </FormGroup>
      )}
      {!hideFields.includes('demographics') && (
        <FormGroup>
          <ModalButton
            buttonProps={{
              icon: hasDemoGraphics ? 'pencil' : 'plus',
              variant: hasDemoGraphics ? 'primary' : 'secondary',
              sx: {
                mt: '15px'
              }
            }}
            buttonLabel={
              hasDemoGraphics
                ? `Demographics (${nonDeletedIPNotEmpty.length})`
                : 'Demographics'
            }
            renderModalActions={toggle => (
              <Button onClick={toggle}>Continue Submission</Button>
            )}
          >
            {() => (
              <>
                <DemographicDataInputs
                  partyCount={values.count || 1}
                  involvedParties={nonDeletedIP && nonDeletedIP}
                  onAdd={() => {
                    let addCount = false
                    if (
                      (values.count || 1) <
                      (nonDeletedIP && nonDeletedIP.length + 1)
                    ) {
                      addCount = true
                    }

                    mergeValues({
                      ...(addCount && {
                        count: (values.count || 1) + 1
                      }),
                      involvedPartyInfo: values.involvedPartyInfo
                        ? [
                            ...values.involvedPartyInfo,
                            { id: values.involvedPartyInfo.length }
                          ]
                        : [{ id: 0 }]
                    })
                  }}
                  onRemove={id =>
                    mergeValues({
                      involvedPartyInfo: values.involvedPartyInfo.map(
                        ip =>
                          ip.id === id && !isEmpty(ip)
                            ? {
                                ...ip,
                                deleted: true
                              }
                            : ip
                      )
                    })
                  }
                  onUpdate={updates => {
                    mergeValues({
                      involvedPartyInfo:
                        values.involvedPartyInfo &&
                        !isEmpty(values.involvedPartyInfo)
                          ? values.involvedPartyInfo.map(ip =>
                              ip.id === updates.id
                                ? {
                                    ...ip,
                                    ...updates
                                  }
                                : ip
                            )
                          : [{ ...updates }]
                    })
                  }}
                />
              </>
            )}
          </ModalButton>
        </FormGroup>
      )}
      {(!hideFields.includes('demographics') ||
        !hideFields.includes('count')) && (
        <Divider margin={'15px'} key={200} />
      )}
      {!hideFields.includes('note') && (
        <FormGroup>
          <Label htmlFor="note">Add Notes / Narrative</Label>
          <RichTextInput
            name="note"
            id="note"
            default={useNoteTemplate && noteTemplate}
            sx={{ height: 'auto' }}
            value={values.note}
            minHeight={'200px'}
            onChange={content => {
              mergeValues({
                note: content
              })
            }}
          />
        </FormGroup>
      )}

      {!hideFields.includes('files') && (
        <FormGroup>
          <Label htmlFor="file">Upload Files</Label>
          <UppyFileUploadButton
            multipleFiles={true}
            onDetach={({ filestackId }) => {
              mergeValues({
                files: values.files.filter(
                  file => file.filestackId !== filestackId
                )
              })
            }}
            onAttach={value => {
              if (value && value.length === 0) {
                return
              }
              mergeValues({
                files: [...files, ...value]
              })
              return {
                success: true
              }
            }}
            files={values.files}
          />
        </FormGroup>
      )}

      {!hideFields.includes('forms') && (
        <FormGroup>
          <Label htmlFor="forms">
            Additional Details{' '}
            {isEditing && (
              <Fragment>
                (New Only){' '}
                <QuestionTooltip
                  style={{ marginLeft: '15px' }}
                  tip={
                    'When editing this incident, you can only add NEW forms.  To edit existing forms, go to the Edit Submissions page or Activity Log.'
                  }
                />
              </Fragment>
            )}
          </Label>
          <FormAttachmentButtonList
            disableAutoSave
            formResponses={values.formResponses}
            featuredForms={currentType && currentType.suggestedForms}
            onDetach={id => {
              mergeValues({
                formResponses: values.formResponses.filter(
                  formR => formR.id !== id
                )
              })
            }}
            onAttach={formResponse => {
              if (!formResponse) {
                return
              }
              mergeValues({
                formResponses: [
                  ...(values.formResponses || []),
                  {
                    ...formResponse,
                    id: formResponse.id || uniqueId()
                  }
                ]
              })
              return true
            }}
            onUpdate={formResponse => {
              mergeValues({
                formResponses: values.formResponses.map(fr =>
                  fr.id === formResponse.id ? formResponse : fr
                )
              })
              return true
            }}
          />
        </FormGroup>
      )}
    </Box>,

    !hideFields.includes('vehicles') && (
      <Box
        key={3}
        sx={{
          py: 2,
          width: '100%'
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {currentVehicles && hasVehicles && (
            <Label style={{ fontWeight: 'semiBold' }}>
              Vehicles Added
            </Label>
          )}

          <ModalButton
            buttonProps={{
              icon: hasVehicles ? 'pencil' : 'plus',
              variant: hasVehicles ? 'link' : 'secondary'
            }}
            buttonLabel={'Add Vehicle'}
          >
            {({ toggle }) => {
              return (
                <AddVehicleInputs
                  onClose={toggle}
                  onAdd={newVal => {
                    if (!newVal.vehicleType) {
                      alert('Please add a vehicle type')
                      return
                    }

                    mergeValues({
                      vehicles: [...currentVehicles, newVal]
                    })
                    toggle()
                  }}
                />
              )
            }}
          </ModalButton>
        </Flex>

        {currentVehicles.map((v, k) =>
          v?.removed ? (
            <Fragment />
          ) : (
            <ListGroupItem sx={{ p: '7px' }}>
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                {v.vehicleType.value} - {v.lNumber}
                <Button
                  variant="link"
                  onClick={() => {
                    mergeValues({
                      vehicles: currentVehicles?.map((v, k2) =>
                        k === k2 ? { ...v, removed: true } : v
                      )
                    })
                  }}
                >
                  Remove
                </Button>
              </Flex>
            </ListGroupItem>
          )
        )}
        <Divider margin={'15px'} key={200} />
      </Box>
    ),

    !hideFields.includes('weather') && (
      <Box
        key={4}
        sx={{
          py: 2,
          width: '100%',
          mb: '30px'
        }}
      >
        <EditWeatherLogModalForm
          handleChange={handleChange}
          values={values}
        />
      </Box>
    ),
    <Box key={6} sx={{ width: '100%' }}>
      {submittable && (
        <StickyButton
          variant="danger"
          size="lg"
          disabled={loading}
          icon={loading && 'spinner'}
          {...buttonOptions}
        >
          {(buttonOptions && buttonOptions.text) || 'Submit'}
        </StickyButton>
      )}
    </Box>
  ]
}

export default SubmissionReviewFields

const involvedPartyOptions = [
  {
    value: 0
  },
  { value: 1, default: true },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
  { value: 11 },
  { value: 12 },
  { value: 13 },
  { value: 14 },
  { value: 15 },
  { value: 16 },
  { value: 17 },
  { value: 18 },
  { value: 19 },
  { value: 20 }
]
