/** @jsxImportSource theme-ui */

export const Table = ({ columns, rows }) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            {columns?.map((c, k) => (
              <th key={k}>{c}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {row.values.map((d, k) => (
                <td key={k}>{d}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
