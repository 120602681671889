/** @jsxImportSource theme-ui */
import { useState, useContext, Fragment, useEffect } from 'react'
import Map from '../Shared/maps/Map'
import Marker from '../Shared/maps/Marker'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'

import { GoogleMapsContext } from 'src/components/layout'

import get from 'lodash/get'
import { Box } from 'theme-ui'
import Button from '../Shared/Elements/Button'
import Label from '../Shared/Elements/Label'
import Input from '../Shared/Elements/Input'
import InputGroup from '../Shared/Elements/InputGroup'

import ModalButton from '../Shared/ModalButton'
import FormError from './FormError'
import { useGetCurrentAgencyContext } from 'src/app/hooks/queries'
import Icon from '../Shared/Icon'

// import TransitLayer from "./google/TransitLayer";

export const StatefulGoogleInput = props => {
  const [input, setInput] = useState(props.value)

  useEffect(() => {
    setInput(props.value)
  }, [props.value])

  return (
    <GoogleMapInput
      {...props}
      value={input}
      handleChange={setInput}
    />
  )
}

const GoogleMapInput = ({
  handleSelect,
  handleChange,
  value,
  inputProps = {},

  mapAsModal,
  showPrettyAddress = false,
  open = false,
  showAuxillaryBtns = true,
  startingLat,
  startingLng
}) => {
  const { currentAgency } = useGetCurrentAgencyContext()
  const [showLatLng, setShowLatLng] = useState(!showPrettyAddress)
  // eslint-disable-next-line
  const [bound, setBound] = useState({})
  const [error, setError] = useState(null)
  const [showMap, toggleMap] = useState(open)
  const {
    loading,
    geoPosition: {
      latitude,
      longitude,
      grabLocation,
      error: geoError,
      loading: geoLoading
    },
    getAddressFromLatLng
  } = useContext(GoogleMapsContext)
  if (loading) return <Fragment />

  const onPlacesChange = address => {
    handleChange({
      position: value && value.position,
      address
    })
  }

  const onPlacesSelect = address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        handleSelect({
          position: value && value.position,
          ...latLng,
          prettyAddress: address
        })
      })
      .catch(error => console.error('Error', error))
  }

  const valueIsLatLng = value && value.lat && value.lng
  const mapLat = valueIsLatLng && value.lat
  const mapLng = valueIsLatLng && value.lng
  const addressInputValue = get(value, 'address') || ''
  const prettyAddress = valueIsLatLng && value.prettyAddress
  const getNewLocation = async () => {
    const res = await grabLocation()?.catch(err => {
      setError(error?.message || "Couldn't get location")
    })

    const pretty =
      res?.coords &&
      (await getAddressFromLatLng({
        lat: res?.coords?.latitude,
        lng: res?.coords?.longitude
      }))

    handleSelect({
      position: value?.position,
      prettyAddress: pretty?.results?.[0]?.formatted_address,
      lat: res?.coords?.latitude,
      lng: res?.coords?.longitude
    })
  }

  const finalVal = !showLatLng
    ? valueIsLatLng
      ? prettyAddress || `${mapLat} , ${mapLng}`
      : addressInputValue
    : valueIsLatLng
    ? `${mapLat} , ${mapLng}`
    : startingLat
    ? `${startingLat} , ${startingLng}`
    : addressInputValue

  const finalLat =
    mapLat ||
    latitude ||
    startingLat ||
    currentAgency?.settings?.latitude ||
    33.6595

  const finalLng =
    mapLng ||
    longitude ||
    startingLng ||
    currentAgency?.settings?.longitude ||
    -117.9988

  const disabled = inputProps.disabled
  return (
    <>
      <PlacesAutocomplete
        value={finalVal}
        onChange={onPlacesChange}
        onSelect={onPlacesSelect}
        name={inputProps.name}
        id={inputProps.id}
        {...inputProps}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <Label
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
              htmlFor={inputProps.name}
            >
              {inputProps.label ? inputProps.label : <div />}
              {finalVal && prettyAddress && (
                <Box
                  sx={{
                    marginRight: '35px'
                  }}
                >
                  <Button
                    disabled={disabled}
                    sx={{ padding: '0px' }}
                    variant="link"
                    type="button"
                    onClick={() => setShowLatLng(!showLatLng)}
                  >
                    {showLatLng
                      ? 'Show Closest Address'
                      : 'Show Coords'}
                  </Button>
                </Box>
              )}
            </Label>

            <InputGroup sx={{ flexWrap: 'nowrap', gap: '5px' }}>
              <Button
                disabled={
                  !showAuxillaryBtns ||
                  geoLoading ||
                  loading ||
                  disabled
                }
                onClick={() => getNewLocation()}
                variant="naked"
                // iconColor="text"
                type="button"
                sx={{
                  display: !showAuxillaryBtns ? 'none' : 'flex',
                  p: '0px 5px'
                }}
                icon={geoLoading || loading ? 'spinner' : 'GPS'}
                iconSize={25}
              />
              <Input
                // onChange={onChange}
                sx={{
                  fontSize: '13px'
                }}
                {...getInputProps({
                  placeholder: 'Search Places...',
                  className: 'location-search-input',
                  ...inputProps,

                  autoComplete: 'nope'
                })}
              />

              {suggestions && (
                <div
                  sx={{
                    position: 'absolute',
                    backgroundColor: 'background',
                    zIndex: 20,
                    top: 40
                  }}
                  className="autocomplete-dropdown-container"
                >
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item'
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          cursor: 'pointer',
                          padding: 10,
                          opacity: 0.5
                        }
                      : {
                          cursor: 'pointer',
                          padding: 10
                        }
                    return (
                      <Box
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </Box>
                    )
                  })}
                </div>
              )}
              {mapAsModal ? (
                <ModalButton
                  buttonProps={{
                    variant: 'link',
                    sx: {
                      padding: '0px',
                      paddingLeft: '7px'
                    },
                    disabled
                  }}
                  renderModalActions={toggle => {
                    return <Button onClick={toggle}>Continue</Button>
                  }}
                  buttonLabel={<Icon icon="map" height={20} />}
                >
                  {() => (
                    <MapComponent
                      onDragEnd={async m => {
                        const lat = m.getPosition().lat()
                        const lng = m.getPosition().lng()
                        const res = await getAddressFromLatLng({
                          lat,
                          lng
                        })

                        handleSelect({
                          ...value,
                          lat,
                          lng,
                          prettyAddress: res
                        })
                      }}
                      onBoundsChanged={arg => setBound(arg)}
                      lat={finalLat}
                      lng={finalLng}
                      zoom={15}
                    />
                  )}
                </ModalButton>
              ) : (
                <Button
                  onClick={() => toggleMap(!showMap)}
                  variant="link"
                  type="button"
                  disabled={!showAuxillaryBtns || disabled}
                  sx={{
                    display: !showAuxillaryBtns ? 'none' : 'flex',
                    p: '0px 5px'
                  }}
                  icon="map"
                  iconSize={25}
                  iconColor={showMap ? 'red' : 'text'}
                />
              )}
            </InputGroup>
            {(error || geoError) && (
              <FormError customError={error || geoError} />
            )}
            {showMap && (
              <MapComponent
                onDragEnd={async m => {
                  const lat = m.getPosition().lat()
                  const lng = m.getPosition().lng()
                  const res = await getAddressFromLatLng({
                    lat,
                    lng
                  })

                  handleSelect({
                    ...value,
                    lat,
                    lng,
                    prettyAddress: res
                  })
                }}
                onBoundsChanged={arg => setBound(arg)}
                lat={finalLat}
                lng={finalLng}
                zoom={15}
              />
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </>
  )
}

export const MapComponent = ({
  lat,
  lng,
  onBoundsChanged = () => {},
  onDragEnd = () => {}
}) => {
  const center = {
    lat: parseFloat(lat),
    lng: parseFloat(lng)
  }
  return (
    <Map
      style={{ marginTop: '5px', width: '100%' }}
      zoom={15}
      center={center}
      events={{ onBoundsChanged }}
    >
      {/* <TransitLayer enabled={transitLayerEnabled} /> */}
      <Marker
        title="Current Location"
        markerSettings={{
          draggable: true
        }}
        events={{
          onDragEnd
        }}
        position={center}
      />
    </Map>
  )
}

export default GoogleMapInput
