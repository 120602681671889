/** @jsxImportSource theme-ui */

import { createContext } from 'react'
import PropTypes from 'prop-types'

import styled from '@emotion/styled'

import useGoogleMapsLoader from 'src/app/hooks/useGoogleMapsLoader'
import useGeoPosition from 'src/app/hooks/useGeoPosition'
import { ToastProvider } from './ToastProvider'

import { useGetCurrentAgency } from 'src/app/hooks/queries'

import MainNav from 'src/app/components/Nav/MainNav/MainNav'

import ConfirmModal, {
  ConfirmContextProvider
} from 'src/app/components/Shared/Elements/ConfirmModal'

import { RefreshSession } from 'src/app/components/LoginScreen/RefreshToken'

import { SmallChatLoader } from './SupportButton'
import { css, Global } from '@emotion/react'

import { VerifyEmailModal } from 'src/app/components/VerifyEmailModal'

import { useStytchMemberSession } from '@stytch/react/b2b'
import { useLocation } from 'react-router'

const LayoutWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
  .ck.ck-balloon-panel.ck-balloon-panel_visible {
    z-index: 9999 !important;
  }
`

export const GoogleMapsContext = createContext({})
export const CurrentAgencyContext = createContext({
  loading: true
})

const GeoContext = ({ children }) => {
  const {
    googleMaps,
    getAddressFromLatLng,
    loading,
    error
  } = useGoogleMapsLoader()
  const geoPosition = useGeoPosition()

  return (
    <GoogleMapsContext.Provider
      value={{
        googleMaps,
        getAddressFromLatLng,
        geoPosition,
        loading,
        error
      }}
    >
      {children}
    </GoogleMapsContext.Provider>
  )
}

const MainLayout = ({ children, minimal, skipAgency }) => {
  const { session } = useStytchMemberSession()

  const currentAgencyData = useGetCurrentAgency(session, skipAgency)

  return (
    <>
      <Global
        styles={css`
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
          .react-datepicker__aria-live {
            display: none !important;
          }
        `}
      />
      <GeoContext>
        <CurrentAgencyContext.Provider value={currentAgencyData}>
          <SmallChatLoader>
            <LayoutWrapper
              sx={{
                height: '100vh',
                overflow: 'hidden'
              }}
              className="root"
            >
              <MainNav minimal={minimal} mainContent={children} />
              <ConfirmModal />
            </LayoutWrapper>
            {session && <RefreshSession />}
            <VerifyEmailModal />
          </SmallChatLoader>
        </CurrentAgencyContext.Provider>
      </GeoContext>
    </>
  )
}

const basicLayout = [
  '/agency/login',
  '/agency/set-up',
  '/agency/auth-success',
  '/agency/oauth',
  '/agency/onboarding',
  '/agency/update-password',
  '/agency/auth-error'
]

const Layout = ({ children, skipAgency }) => {
  const { pathname } = useLocation()
  return (
    <ConfirmContextProvider>
      <ToastProvider>
        <MainLayout
          minimal={basicLayout?.includes(pathname)}
          skipAgency={skipAgency}
        >
          {children}
        </MainLayout>
      </ToastProvider>
    </ConfirmContextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
